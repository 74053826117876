import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { commentQueueQuery$key } from "~/queries/__generated__/commentQueueQuery.graphql";

import {
  CommentItems,
  Container,
  FirstRow,
  MoreLink,
  SecondRow,
  StyledContentItemHomeComment,
  StyledContentItemHomeHarry,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: commentQueueQuery$key;
};

export const HomeComment: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment commentQueueQuery on Query
      @argumentDefinitions(
        commentQueueName: { type: "String!" }
        commentHarrySectionIds: { type: "[String]" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
      ) {
        commentQueue: queue(filter: { name: $commentQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...homeCommentContentItemContent
                }
              }
            }
          }
        }
        harryContents: contents(
          contentTypes: [GALLERY]
          first: 1
          filter: { sectionIds: $commentHarrySectionIds }
          articleExcludeInput: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) {
          edges {
            node {
              ... on Content {
                entityId
                ...homeHarryContentItemContent
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const commentItems = data?.commentQueue?.items?.edges;
  const harryContent = first(data?.harryContents?.edges);
  const firstRowItems = commentItems?.slice(0, 3);
  const secondRowItems = commentItems?.slice(3, commentItems?.length);

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/opinion",
      category: "Homepage Depth",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (commentItems?.length === 0) return null;
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Opinion,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title pathname="/comment">COMMENT</Title>
        <CommentItems>
          <FirstRow>
            {firstRowItems?.map(({ node }) => (
              <StyledContentItemHomeComment
                key={node.entityId}
                reference={node}
                onClick={entityId => {
                  sendGATracking({
                    action: "Click/opinion",
                    category: "Homepage Depth",
                    label: entityId,
                  });
                }}
              />
            ))}
          </FirstRow>
          <SecondRow>
            {secondRowItems?.map(({ node }) => (
              <StyledContentItemHomeComment
                key={node.entityId}
                reference={node}
                onClick={entityId => {
                  sendGATracking({
                    action: "Click/opinion",
                    category: "Homepage Depth",
                    label: entityId,
                  });
                }}
              />
            ))}
            {harryContent && (
              <StyledContentItemHomeHarry
                reference={harryContent.node}
                onClick={entityId => {
                  sendGATracking({
                    action: "Click/opinion",
                    category: "Homepage Depth",
                    label: entityId,
                  });
                }}
              />
            )}
          </SecondRow>
        </CommentItems>
        <MoreLink pathname="/opinion">MORE COMMENT</MoreLink>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeComment.displayName = "HomeComment";
