import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homeAroundSecondaryContentItemContent$key } from "~/queries/__generated__/homeAroundSecondaryContentItemContent.graphql";

import { Container, Content, Headline, StyledEntityLink } from "./styles";
export type Props = {
  reference: homeAroundSecondaryContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeAroundSecondaryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent @arguments(withHeadline: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <Content>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHeadlineSponsorStyle: true,
              })}
            </Headline>
          </Content>
        </StyledEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeAroundSecondary";

export const ContentItemHomeAroundSecondary = withHighlightedHeadline(Component);
