import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { HomeTrendingTopic } from "~/components/home/trending-topic";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { mostPopularQueueQuery$key } from "~/queries/__generated__/mostPopularQueueQuery.graphql";

import {
  Container,
  DisplayNumber,
  MostPopularItem,
  MostPopularItems,
  StyledContentItemHomeSecondary,
  Title,
  TrendingTopics,
} from "./styles";

type Props = {
  className?: string;
  reference: mostPopularQueueQuery$key;
};

export const HomeMostPopular: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment mostPopularQueueQuery on Query
      @argumentDefinitions(trendingTopicsQueueName: { type: "String!" }) {
        mostPopularQueueQuery: queue(
          filter: { name: "scmp_trending_section_scmp_trending_section_homepage_last_24_hours" }
        ) {
          items(first: 5) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
        trendingTopicsQueue: queue(filter: { name: $trendingTopicsQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Topic {
                  entityId
                  ...trendingTopicTopic
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const mostPopularItems = data?.mostPopularQueueQuery?.items?.edges;
  const trendingTopics = data?.trendingTopicsQueue?.items?.edges;

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/most_popular",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (mostPopularItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.MostPopular,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>MOST POPULAR</Title>
        <MostPopularItems>
          {mostPopularItems?.map(({ node }, index) => (
            <MostPopularItem key={node.entityId}>
              <DisplayNumber>{index + 1}</DisplayNumber>
              <StyledContentItemHomeSecondary
                reference={node}
                onClick={entityId => {
                  sendGATracking({
                    action: "Click/most_popular",
                    category: "Homepage Depth",
                    label: entityId,
                  });
                }}
              />
            </MostPopularItem>
          ))}
        </MostPopularItems>
        <Title>TRENDING TOPICS</Title>
        <TrendingTopics>
          {trendingTopics?.map(({ node }) => (
            <HomeTrendingTopic key={node.entityId} reference={node} />
          ))}
        </TrendingTopics>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeMostPopular.displayName = "HomeMostPopular";
