/**
 * @generated SignedSource<<6079b94f4205dce0df4400438cfb7e21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type storyPackageQuery$data = {
  readonly storyPackageArticles: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "homeStoryPackageLeadContentItemContent" | "homeStoryPackageTailContentItemContent">;
        };
      }>;
    } | null | undefined;
    readonly refTerms: ReadonlyArray<{
      readonly __typename: "Newsletter";
      readonly entityId: string;
      readonly latestArticleLink: string | null | undefined;
      readonly name: string;
    } | {
      readonly __typename: "Section";
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
    } | {
      readonly __typename: "Topic";
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined> | null | undefined;
    readonly settings: unknown | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "storyPackageQuery";
};
export type storyPackageQuery$key = {
  readonly " $data"?: storyPackageQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"storyPackageQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "entityLink"
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "followButtonBase"
  },
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "storyPackageArticlesQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "storyPackageQuery",
  "selections": [
    {
      "alias": "storyPackageArticles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "storyPackageArticlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "refTerms",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "latestArticleLink",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "type": "Newsletter",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "Section",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "Topic",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "homeStoryPackageLeadContentItemContent"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "homeStoryPackageTailContentItemContent"
                        }
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:3)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a0ccfe7d9734603fd2a9fea102de511e";

export default node;
