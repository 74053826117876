import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useCallback } from "react";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";

export const commentHash = "#comments";

export const useTopStoriesImpressionTracking = () => {
  const firstSetImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_6",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetFirstSetElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: firstSetImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const secondSetImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_9",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetSecondSetElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: secondSetImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const thirdSetImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_13",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetThirdSetElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: thirdSetImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const fourthSetImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_17",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetFourthSetElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: fourthSetImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const firstFoldImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/first_fold",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetFirstFoldElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: firstFoldImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const secondFoldImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/second_fold",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetSecondFoldElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: secondFoldImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const thirdFoldImpressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/third_fold",
      category: "Homepage Depth",
    }),
    [],
  );
  const {
    captureTrackImpressionEventTargetElement: captureTrackImpressionEventTargetThirdFoldElement,
  } = useTrackImpressionEventByIntersection<Event, null>({
    gaTrackingHandler: sendGATracking,
    getEvent: thirdFoldImpressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  return {
    captureTrackImpressionEventTargetFirstFoldElement,
    captureTrackImpressionEventTargetFirstSetElement,
    captureTrackImpressionEventTargetFourthSetElement,
    captureTrackImpressionEventTargetSecondFoldElement,
    captureTrackImpressionEventTargetSecondSetElement,
    captureTrackImpressionEventTargetThirdFoldElement,
    captureTrackImpressionEventTargetThirdSetElement,
  };
};
