import type { FunctionComponent, ReactNode } from "react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export type Props = {
  children?: ReactNode;
  selector: string;
};

export const Portal: FunctionComponent<Props> = ({ children, selector }) => {
  const reference = useRef<Element | null>(null);

  useEffect(() => {
    reference.current = document.querySelector(selector);
  }, [selector]);

  return reference.current ? createPortal(children, reference.current) : null;
};

Portal.displayName = "Portal";
