import {
  findFirstNonEmptyString,
  notEmpty,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { focusArticleHomeFocusArticleQuery$key } from "~/queries/__generated__/focusArticleHomeFocusArticleQuery.graphql";

import { checkIsLiveArticle, getArticleTitle, getPodcastTitle, getVideoTitle } from "./helpers";
import { Container, LiveDot, StyledContentItemHomeFocus, Title } from "./styles";

type Props = {
  reference: focusArticleHomeFocusArticleQuery$key;
};

type Settings = {
  custom_text?: string;
};

export const HomeFocusArticle: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment focusArticleHomeFocusArticleQuery on Query
      @argumentDefinitions(focusQueueName: { type: "String!" }) {
        focus: queue(filter: { name: $focusQueueName }) {
          settings
          items(first: 1) {
            edges {
              node {
                ...homeFocusContentItemContent
                ...helpersGetArticleTitleArticle
                ...helpersGetPodcastTitlePodcast
                ...helpersGetVideoTitleVideo
                ...helpersCheckIsLiveArticle
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const article = first(data?.focus?.items?.edges)?.node;

  const title = useMemo(() => {
    const customText = (data?.focus?.settings as Settings)?.custom_text ?? "";
    const videoTitle = getVideoTitle(article);
    const articleTitle = getArticleTitle(article);
    const podcastTitle = getPodcastTitle(article);
    return findFirstNonEmptyString(customText, podcastTitle, videoTitle, articleTitle, "Focus");
  }, [article, data?.focus?.settings]);

  const { isLive = false, isLiveArticle = false } = notEmpty(article)
    ? checkIsLiveArticle(article)
    : {};

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: `Impression/flexi_unit/${title}` as const,
      category: "Homepage Depth",
    }),
    [title],
  );

  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (!article) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.FlexiUnitFocus,
      }}
    >
      <Container>
        <Title>
          {isLiveArticle && <LiveDot $isLive={isLive} />}
          <span>{title}</span>
        </Title>
        <StyledContentItemHomeFocus
          ref={captureTrackImpressionEventTargetElement}
          reference={article}
          topicLinkVariant={{ type: "main" }}
          onClick={entityId => {
            sendGATracking({
              action: `Click/flexi_unit/${title}` as const,
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeFocusArticle.displayName = "HomeFocusArticle";
