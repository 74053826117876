import { theme, useResponsive } from "@product/scmp-sdk";
import { forwardRef } from "react";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { useUserFollow } from "~/components/follow-button/hooks";
import { MyNewsLink } from "~/components/home/my-news-daily-five/my-news-link";
import { RecommendArticles } from "~/components/home/my-news-daily-five/recommend-articles";
import { SetupCallToAction } from "~/components/home/my-news-daily-five/setup-call-to-action";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { tracking } from "~/data";
import { useMyNews } from "~/lib/hooks";

import MyNewsLogo from "./myNewsLogo.svg";
import {
  Container,
  ContentContainer,
  DownloadContainer,
  LinksContainer,
  LogoContainer,
  StyledBaseLink,
  SubTitle,
  Title,
  TitleContainer,
} from "./styles";

export const MyNewsDailyFive = forwardRef<HTMLDivElement>((_, reference) => {
  const isHomeDesktop = useResponsive(theme.breakpoints.up("homeDesktop"), false);

  const downloadLink = isHomeDesktop
    ? "https://app.scmp.com/"
    : "https://scmp.onelink.me/3586748601/43f04bbb";

  const { data } = useUserFollow({ suspense: true });
  const { initialHasEnoughFollowedItems } = useMyNews({ suspense: true });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.MyDaily5,
      }}
    >
      <Container ref={reference}>
        <LogoContainer>
          <MyNewsLogo />
        </LogoContainer>
        <TitleContainer>
          <Title>My Daily 5</Title>
          <SubTitle>Five daily stories, curated for you</SubTitle>
        </TitleContainer>
        <ContentContainer>
          {initialHasEnoughFollowedItems ? (
            <RecommendArticles
              authorIds={data?.authors}
              sectionIds={data?.sections}
              topicIds={data?.topics}
            />
          ) : (
            <SetupCallToAction />
          )}
        </ContentContainer>
        <LinksContainer>
          {initialHasEnoughFollowedItems && (
            <MyNewsLink
              onClick={destinationUrl => {
                sendGATracking({
                  action: "Homepage/more stories/Click",
                  category: "My News",
                  label: destinationUrl,
                });
              }}
            >
              more stories you might like
            </MyNewsLink>
          )}
          <DownloadContainer>
            <StyledBaseLink pathname={downloadLink}>Download SCMP app</StyledBaseLink>
            <span> for the best experience</span>
          </DownloadContainer>
        </LinksContainer>
      </Container>
    </BaseLinkContextProvider>
  );
});

MyNewsDailyFive.displayName = "MyNewsDailyFive";
