/**
 * @generated SignedSource<<c4d422451f7934db72be36c0119d365d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type heroArticleArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"homeHeroArticleContentItemContent">;
  readonly " $fragmentType": "heroArticleArticle";
};
export type heroArticleArticle$key = {
  readonly " $data"?: heroArticleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"heroArticleArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "heroArticleArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeHeroArticleContentItemContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "5ce4e49d3b309077876d8dfd629a5a92";

export default node;
