import { scmpPlus } from "@product/scmp-sdk";
import sortBy from "lodash/sortBy";
import type { FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ContentItemDailyFive } from "~/components/content/content-item-render/variants/home-daily-five";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { recommendArticlesQuery } from "~/queries/__generated__/recommendArticlesQuery.graphql";

import { useMyNewDailyFiveArticleIds } from "./hooks";
import {
  ArticleNode,
  Container,
  MarigoldDot,
  SerialNumber,
  SerialNumberAndMarigoldDot,
} from "./styles";

type Props = {
  authorIds?: string[];
  sectionIds?: string[];
  topicIds?: string[];
};

export const RecommendArticles: FunctionComponent<Props> = ({
  authorIds,
  sectionIds,
  topicIds,
}) => {
  const { recommendedArticleIds } = useMyNewDailyFiveArticleIds({
    authorIds,
    sectionIds,
    topicIds,
  });

  const recommendArticlesResult = useLazyLoadQuery<recommendArticlesQuery>(
    graphql`
      query recommendArticlesQuery($entityIds: [String], $scmpPlusPaywallTypeIds: [String]) {
        articles(
          exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          filter: { entityIds: $entityIds }
          first: 5
        ) {
          edges {
            node {
              ...entityLink
              ...homeDailyFiveContentItemContent
              entityId
            }
          }
        }
      }
    `,
    {
      entityIds: recommendedArticleIds,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    { fetchPolicy: "store-or-network" },
  );

  const articleNodes = recommendArticlesResult.articles.edges.map(edge => edge.node);

  const sortedArticleNodes = sortBy(articleNodes, node =>
    recommendedArticleIds?.indexOf(node.entityId),
  );

  return (
    <Container>
      {sortedArticleNodes.map((node, index) => (
        <ArticleNode key={node.entityId}>
          <SerialNumberAndMarigoldDot>
            <SerialNumber>{index + 1}</SerialNumber>
            <MarigoldDot />
          </SerialNumberAndMarigoldDot>
          <ContentItemDailyFive
            reference={node}
            onClick={entityId => {
              sendGATracking({
                action: `Homepage/Daily${index + 1}/Click` as const,
                category: "My News",
                label: entityId,
              });
            }}
          />
        </ArticleNode>
      ))}
    </Container>
  );
};

RecommendArticles.displayName = "RecommendArticles";
