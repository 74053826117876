import {
  BaseFooter,
  Footer,
  PageType,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { memo, useCallback } from "react";
import type { FunctionComponent } from "react";

import { Portal } from "~/components/common/portal";
import { useNewsletterWidget } from "~/components/newsletter/newsletter-widget-container/hooks";
import type { Event } from "~/components/tracking/google-analytics/types";
import type { Event as Ga4Event } from "~/components/tracking/google-analytics-4/types";
import { rosettaAtom } from "~/lib/rosetta";
import { useCurrentPageType } from "~/lib/router/hooks";
import { FooterElementID } from "~/pages/_app/consts";

import { DynamicHomeFooter, DynamicPostiesFooter, DynamicStyleFooter } from "./dynamics";
import { Container } from "./styles";
import type { ContainerProps } from "./styles";

export enum FooterVariant {
  Home = "home",
  MagazinesStyle = "magazines-style",
  Posties = "posties",
}

export type Props = {
  className?: string;
  hasPrintEditionFooter?: boolean;
  variant?: ContainerProps["$variant"];
};

const AppFooter_: FunctionComponent<Props> = ({ className, hasPrintEditionFooter, variant }) => {
  const { dataMatrix } = useNewsletterWidget({
    contentType: "footer",
    eventCategory: "Newsletter (Footer - Inline)",
    section: "",
  });

  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;
  const subscriberPhase = asyncRosettaState?.result?.tracking?.ga4.subscriberPhase;
  const currentPageType = useCurrentPageType();

  const subscribeButtonImpressionUaEvent = useCallback<() => Event>(
    () => ({
      action: "Subscription Entry Point/Footer/Impression",
      category: "Subscription Entry Point",
    }),
    [],
  );
  const subscribeButtonImpressionGA4Event = useCallback<() => Ga4Event>(
    () => ({
      action: "imp",
      category: "subs",
      customized_parameters: {
        cta_type: "subscribe",
        subscriber_phase: subscriberPhase,
        trigger_point: (() => {
          switch (currentPageType) {
            case PageType.Article:
              return "article footer";
            case PageType.Section:
              return "section footer";
            default:
              return "footer";
          }
        })(),
      },
      subcategory: "entry_point",
    }),
    [currentPageType, subscriberPhase],
  );
  const { captureTrackImpressionEventTargetElement: captureSubscribeButtonImpressionEvent } =
    useTrackImpressionEventByIntersection({
      gaTrackingHandler: null,
      getEvent: subscribeButtonImpressionUaEvent,
      getGa4Event: subscribeButtonImpressionGA4Event,
      options: { isSendGA4Tracking: true, isSendGATracking: true, shouldSendOnce: true },
    });

  const renderFooter = () => {
    switch (variant) {
      case FooterVariant.Posties:
        return <DynamicPostiesFooter />;
      case FooterVariant.MagazinesStyle:
        return <DynamicStyleFooter />;
      case FooterVariant.Home:
        return <DynamicHomeFooter />;

      default:
        return (
          <div ref={isScmpSubscriber ? null : captureSubscribeButtonImpressionEvent}>
            <BaseFooter
              dataMatrix={dataMatrix}
              newsletterItems={[]}
              queryParametersForSocialButtons={queryParametersForSocialButtons}
            />
          </div>
        );
    }
  };

  const queryParametersForSocialButtons = {};
  return (
    <Portal selector={`#${FooterElementID}`}>
      <Container $variant={variant} className={className}>
        {hasPrintEditionFooter ? (
          <Footer
            dataMatrix={dataMatrix}
            queryParametersForSocialButtons={queryParametersForSocialButtons}
          />
        ) : (
          renderFooter()
        )}
      </Container>
    </Portal>
  );
};

export const AppFooter = memo(AppFooter_);

AppFooter.displayName = "AppFooter";
