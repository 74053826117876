/**
 * @generated SignedSource<<d999424736bef6e4c2ee09ed3dbe3a3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type twoSectionTopWidgetQueue$data = {
  readonly variantTwoItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "homeSecondaryContentItemContent" | "homeSectionLeadSecondaryContentItemContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "twoSectionTopWidgetQueue";
};
export type twoSectionTopWidgetQueue$key = {
  readonly " $data"?: twoSectionTopWidgetQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"twoSectionTopWidgetQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeEntityIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "twoSectionTopWidgetQueue",
  "selections": [
    {
      "alias": "variantTwoItems",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityIds",
              "variableName": "excludeEntityIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "exclude"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "entityLink"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeSecondaryContentItemContent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeSectionLeadSecondaryContentItemContent"
                    }
                  ],
                  "type": "Content",
                  "abstractKey": "__isContent"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Queue",
  "abstractKey": null
};

(node as any).hash = "10604b16611624c0b6a6f43f71e965f0";

export default node;
