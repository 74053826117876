import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "~/components/common/base-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-inline-size: 100%;
  margin-inline-start: 4.5px;
  ${props => props.theme.breakpoints.up("tablet")} {
    max-inline-size: 300px;
  }
`;

export const Timeline = styled(BaseLink)`
  position: relative;

  padding-inline-start: 20px;

  &:after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";

    inline-size: 9px;
    block-size: 9px;
    margin-block-start: 3px;
    margin-inline-start: -5px;

    border-radius: 50%;

    background: #ffca05;
  }

  & + & {
    margin-block-start: 16px;
  }
`;

export const SnippetTitle = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 18.2px;
`;

export const DateContainer = styled.div`
  margin-block-end: 4px;

  color: #000000;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  line-height: 14px;
`;
