import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { heroArticleArticle$key } from "~/queries/__generated__/heroArticleArticle.graphql";

import { StyledContentItemHomeHeroArticle } from "./styles";

type Props = {
  reference: heroArticleArticle$key;
};

export const HomeHeroArticle: FunctionComponent<Props> = ({ reference }) => {
  const article = useFragment(
    graphql`
      fragment heroArticleArticle on Article {
        ...homeHeroArticleContentItemContent
      }
    `,
    reference,
  );

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_1",
      category: "Homepage Depth",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (!article) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <StyledContentItemHomeHeroArticle
        ref={captureTrackImpressionEventTargetElement}
        reference={article}
        topicLinkVariant={{ type: "main" }}
        onClick={entityId => {
          sendGATracking({
            action: "Click/top_story_1",
            category: "Homepage Depth",
            label: entityId,
          });
        }}
      />
    </BaseLinkContextProvider>
  );
};

HomeHeroArticle.displayName = "HomeHeroArticle";
