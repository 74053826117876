import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import SpotlightLogo from "./logo-spotlight.svg";

export const Container = styled.div`
  margin-block-start: 32px;
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 48px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const StyledLogo = styled(SpotlightLogo)``;

export const Description = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;

    margin-inline-start: 24px;

    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-family: ${fontRobotoCondensed};
    font-style: italic;
    line-height: 19px;
  }
`;
