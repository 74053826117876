import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { Figure, StyledImage } from "~/components/author/author-avatar/styles";
import { EntityLink } from "~/components/entity-link";

export const Container = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
`;
export const ContentContainer = styled.div`
  display: grid;
  grid: "author-links author-images" / minmax(0, 1fr) 40px;
  gap: 12px;

  margin-block-end: 12px;
  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "author-images"
      "author-links"
      / minmax(0, 1fr);
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid: "author-links author-images" / minmax(0, 1fr) 40px;
  }
`;

export const Content = styled.div``;
export const AuthorLinks = styled.div`
  grid-area: author-links;

  margin-block-end: 4px;
`;
export const AuthorImages = styled.div`
  grid-area: author-images;

  ${StyledImage} {
    background-color: transparent;
  }

  ${Figure}:before {
    inset: -2px;

    border-style: solid;
    border-width: 3px;
  }
`;

export const Headline = styled.div`
  font-weight: 700;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
    font-family: ${fontRoboto};
  }
`;
