import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { aroundItemQueueItemsEdge$key } from "~/queries/__generated__/aroundItemQueueItemsEdge.graphql";

import {
  Container,
  Content,
  StyledContentItemHomeAroundMorningStudioPrimary,
  StyledContentItemHomeAroundMorningStudioSecondary,
  StyledContentItemHomeAroundPrimary,
  StyledContentItemHomeAroundSecondary,
  Title,
} from "./styles";

const handleClick = (entityId = "") => {
  sendGATracking({
    action: "Click/around_scmp",
    category: "Homepage Depth",
    label: entityId,
  });
};

type Props = {
  className?: string;
  reference: aroundItemQueueItemsEdge$key;
  title: string;
  urlAlias: string;
};

export const HomeAroundItem: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  title,
  urlAlias,
}) => {
  const data = useFragment(
    graphql`
      fragment aroundItemQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            entityId
            ...homeAroundPrimaryContentItemContent
            ...homeAroundSecondaryContentItemContent
            ...homeAroundMorningStudioPrimaryContentItemContent
            ...homeAroundMorningStudioSecondaryContentItemContent
          }
        }
      }
    `,
    reference_,
  );

  const renderAroundItems = () => {
    const isMorningStudio = title === "MORNING STUDIO";
    return data?.map((item, key) => {
      if (isMorningStudio) {
        return key === 0 ? (
          <StyledContentItemHomeAroundMorningStudioPrimary
            key={item?.node.entityId}
            reference={item?.node}
            onClick={handleClick}
          />
        ) : (
          <StyledContentItemHomeAroundMorningStudioSecondary
            key={item?.node.entityId}
            reference={item?.node}
            onClick={handleClick}
          />
        );
      } else {
        return key === 0 ? (
          <StyledContentItemHomeAroundPrimary
            key={item?.node.entityId}
            reference={item?.node}
            onClick={handleClick}
          />
        ) : (
          <StyledContentItemHomeAroundSecondary
            key={item?.node.entityId}
            reference={item?.node}
            onClick={handleClick}
          />
        );
      }
    });
  };

  return (
    <Container className={className}>
      <Title pathname={urlAlias}>{title}</Title>
      <Content>{renderAroundItems()}</Content>
    </Container>
  );
};

HomeAroundItem.displayName = "HomeAroundItem";
