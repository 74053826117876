import { FollowType, notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { storyPackageQuery$key } from "~/queries/__generated__/storyPackageQuery.graphql";

import {
  Articles,
  Container,
  StyledContentItemHomeStoryPackageLead,
  StyledContentItemHomeStoryPackageTail,
  StyledTopicFollowButton,
  TopicBadge,
  TopicName,
  TopicNameBaseLink,
  TopicNameEntityLink,
} from "./styles";

type Props = {
  reference: storyPackageQuery$key;
};

type Settings = {
  custom_text?: string;
};

export const HomeStoryPackage: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment storyPackageQuery on Query
      @argumentDefinitions(storyPackageArticlesQueueName: { type: "String!" }) {
        storyPackageArticles: queue(filter: { name: $storyPackageArticlesQueueName }) {
          refTerms {
            ... on Newsletter {
              name
              entityId
              latestArticleLink
              __typename
            }
            ... on Section {
              name
              ...entityLink
              ...followButtonBase
              __typename
            }
            ... on Topic {
              name
              ...entityLink
              ...followButtonBase
              __typename
            }
          }
          settings
          items(first: 3) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...entityLink
                  ...homeStoryPackageLeadContentItemContent
                  ...homeStoryPackageTailContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/feature_package",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const handleClick = (entityId?: string) => {
    sendGATracking({
      action: "Click/feature_package",
      category: "Homepage Depth",
      label: entityId,
    });
  };

  const articleEdges = data?.storyPackageArticles?.items?.edges ?? [];

  const [leadArticleEdge, ...tailArticleEdges] = articleEdges;
  const leadArticle = leadArticleEdge?.node;

  if (!leadArticle) return null;

  const renderTopic = () => {
    const terms = data?.storyPackageArticles?.refTerms?.[0];
    const customText = (data?.storyPackageArticles?.settings as Settings)?.custom_text;
    const typename = terms?.__typename;

    if (typename && typename !== "%other") {
      const displayText = customText === "" ? terms.name : customText;
      const topicName = <TopicName>{displayText}</TopicName>;

      switch (typename) {
        case "Newsletter":
          return terms?.latestArticleLink ? (
            <TopicNameBaseLink pathname={terms.latestArticleLink}>{topicName}</TopicNameBaseLink>
          ) : (
            topicName
          );
        case "Section":
        case "Topic":
          if (notEmpty(terms))
            return (
              <>
                <TopicNameEntityLink reference={terms}>{topicName}</TopicNameEntityLink>
                <StyledTopicFollowButton
                  reference={terms}
                  source="Homepage_feature_package"
                  type={typename === "Section" ? FollowType.Section : FollowType.Topic}
                />
              </>
            );

          return displayText;
      }
    } else {
      return <TopicName>{customText}</TopicName>;
    }
  };

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.StoryPackage,
      }}
    >
      <Container ref={captureTrackImpressionEventTargetElement}>
        <TopicBadge>{renderTopic()}</TopicBadge>

        <Articles>
          <StyledContentItemHomeStoryPackageLead reference={leadArticle} onClick={handleClick}>
            {tailArticleEdges.map(({ node }, index) => (
              <StyledContentItemHomeStoryPackageTail
                key={index}
                reference={node}
                onClick={() => handleClick(node.entityId)}
              />
            ))}
          </StyledContentItemHomeStoryPackageLead>
        </Articles>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeStoryPackage.displayName = "HomeStoryPackage";
