import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { Figure } from "~/components/content/content-cover-image/styles";
import { EntityLink } from "~/components/entity-link";
import { Icon as PlayIcon } from "~/components/video/video-duration-overlay/variants/homepage/styles";

export const ContentContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 20px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
`;

export const Section = styled.div`
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  text-transform: uppercase;
`;

export const Headline = styled.div`
  font-weight: 400;
  font-size: 15px;
  font-family: ${fontMerriweather};
  font-style: normal;
  line-height: 21px;
`;

export const ActionBar = styled.div`
  position: relative;

  padding-inline-end: 9.4px;

  &::after {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-end: 0;
    content: "|";

    color: #999999;

    transform: translateY(-50%);
  }

  time {
    color: #999999;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
  }
`;

export const ActionBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;

  margin-block-end: 8px;
`;

export const CoverImage = styled.div`
  position: relative;

  cursor: pointer;

  ${Figure} {
    aspect-ratio: 3/2;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 80px;

    ${PlayIcon} {
      display: none;
    }
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    block-size: unset;
    ${PlayIcon} {
      display: block;
    }
  }
`;
