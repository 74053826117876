import { notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { VariantProps } from "~/components/home/section-top/variant/types";
import type { oneSectionTopWidgetQueue$key } from "~/queries/__generated__/oneSectionTopWidgetQueue.graphql";

import {
  Container,
  Primary,
  PrimaryRest,
  Secondary,
  StyledContentItemHomeSecondary,
  StyledContentItemHomeSectionLeadPrimary,
} from "./styles";

type Props = {
  className?: string;
  reference: oneSectionTopWidgetQueue$key;
} & VariantProps;

export const SectionTopWidgetOne: FunctionComponent<Props> = ({
  className,
  onClick,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment oneSectionTopWidgetQueue on Queue
      @argumentDefinitions(excludeEntityIds: { type: "[String]" }) {
        variantOneItems: items(first: 7, exclude: { entityIds: $excludeEntityIds }) {
          edges {
            node {
              ... on Content {
                ...entityLink
                ...homeSecondaryContentItemContent
                ...homeSectionLeadPrimaryContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const items =
    data?.variantOneItems?.edges?.map(({ node }) => node).filter(node => notEmpty(node)) ?? [];

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);
  if (items.length === 0) return null;

  const [leading, one, two, ...rest] = items;

  return (
    <Container className={className}>
      <Primary>
        <StyledContentItemHomeSectionLeadPrimary
          reference={leading}
          withComment={true}
          onClick={entityId => onClick(entityId)}
        >
          <PrimaryRest>
            <StyledContentItemHomeSecondary
              reference={one}
              skipHighlight={true}
              withComment={true}
              onClick={entityId => onClick(entityId)}
            />
            <StyledContentItemHomeSecondary
              reference={two}
              skipHighlight={true}
              withComment={true}
              onClick={entityId => onClick(entityId)}
            />
          </PrimaryRest>
        </StyledContentItemHomeSectionLeadPrimary>
      </Primary>

      <Secondary>
        {rest?.slice(0, 4)?.map((node, index) => (
          <StyledContentItemHomeSecondary
            key={index}
            reference={node}
            withComment={isMobile}
            withImage={true}
            onClick={entityId => onClick(entityId)}
          />
        ))}
      </Secondary>
    </Container>
  );
};

SectionTopWidgetOne.displayName = "SectionTopWidgetOne";
