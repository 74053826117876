/* eslint-disable relay/unused-fields */
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { tracking } from "~/data";
import type { topStoriesQueueItemsEdge$key } from "~/queries/__generated__/topStoriesQueueItemsEdge.graphql";

import { useTopStoriesImpressionTracking } from "./hooks";
import { Container } from "./styles";
import { HomeTopStoriesBlock } from "./top-stories-block";

type Props = {
  adZone: string;
  className?: string;
  reference: topStoriesQueueItemsEdge$key;
};

export const HomeTopStories: FunctionComponent<Props> = ({ adZone, className, reference }) => {
  const items = useFragment(
    graphql`
      fragment topStoriesQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        ...topStoriesBlockQueueItemsEdge
      }
    `,
    reference,
  );

  const {
    captureTrackImpressionEventTargetFirstFoldElement,
    captureTrackImpressionEventTargetFirstSetElement,
    captureTrackImpressionEventTargetFourthSetElement,
    captureTrackImpressionEventTargetSecondFoldElement,
    captureTrackImpressionEventTargetSecondSetElement,
    captureTrackImpressionEventTargetThirdFoldElement,
    captureTrackImpressionEventTargetThirdSetElement,
  } = useTopStoriesImpressionTracking();

  if (!items) return null;

  const firstStoriesSet = items.slice(0, 4);
  const secondStoriesSet = items.slice(4, 8);
  const thirdStoriesSet = items.slice(8, 12);
  const fourthStoriesSet = items.slice(12);

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container>
        <div ref={captureTrackImpressionEventTargetFirstFoldElement} />
        <HomeTopStoriesBlock
          adZone={adZone}
          className={className}
          isShowMLrecAd={true}
          ref={captureTrackImpressionEventTargetFirstSetElement}
          reference={firstStoriesSet}
          onClick={entityId => {
            sendGATracking({
              action: "Click/top_story_6",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
        <div ref={captureTrackImpressionEventTargetSecondFoldElement} />
        <HomeTopStoriesBlock
          className={className}
          ref={captureTrackImpressionEventTargetSecondSetElement}
          reference={secondStoriesSet}
          onClick={entityId => {
            sendGATracking({
              action: "Click/top_story_9",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
        <HomeTopStoriesBlock
          className={className}
          ref={captureTrackImpressionEventTargetThirdSetElement}
          reference={thirdStoriesSet}
          onClick={entityId => {
            sendGATracking({
              action: "Click/top_story_13",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
        <div ref={captureTrackImpressionEventTargetThirdFoldElement} />
        <HomeTopStoriesBlock
          adZone={adZone}
          className={className}
          isShowAds={true}
          ref={captureTrackImpressionEventTargetFourthSetElement}
          reference={fourthStoriesSet}
          onClick={entityId => {
            sendGATracking({
              action: "Click/top_story_17",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeTopStories.displayName = "HomeTopStories";
