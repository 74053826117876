import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import { formatInTimeZone } from "~/lib/utils";
import type { homeHarryContentItemContent$key } from "~/queries/__generated__/homeHarryContentItemContent.graphql";

import {
  Container,
  CoverImage,
  DisplayDate,
  Headline,
  StyledCoverEntityLink,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: homeHarryContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeHarryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize320x240: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const dateTimeFormat = "d MMM yyyy";

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <Headline>{"Harry’s View | "}</Headline>
          <DisplayDate>{formatInTimeZone(new Date(), dateTimeFormat)}</DisplayDate>
        </StyledEntityLink>

        <StyledCoverEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size320x240",
                mobileUp: "size320x240",
                tabletUp: "size320x240",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeHarry";

export const ContentItemHomeHarry = withHighlightedHeadline(Component);
