/**
 * @generated SignedSource<<da487bddfd36c19af413f539bd6e0bc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeSectionLeadSecondaryContentItemContent$data = {
  readonly entityId: string;
  readonly topics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentCoverImageContent" | "entityLink" | "hooksContentItemProvidersContent" | "hooksUseContentSponsorContent">;
  readonly " $fragmentType": "homeSectionLeadSecondaryContentItemContent";
};
export type homeSectionLeadSecondaryContentItemContent$key = {
  readonly " $data"?: homeSectionLeadSecondaryContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeSectionLeadSecondaryContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeSectionLeadSecondaryContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "followButtonBase"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withSize1200x800",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "contentCoverImageContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseContentSponsorContent"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSponsorTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSummary",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withTopicLink",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "3cd09237642d5db46b0e62f4fe674409";

export default node;
