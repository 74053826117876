import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "~/components/entity-link";
import { HomeFollowButton } from "~/components/home/follow-button";

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding-block: 6px;
  padding-inline: 8px;

  border-radius: 3px;

  background: #f2f2f2;
`;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  margin-inline-start: 8px;
  button {
    margin-block: auto;
    padding: 0;
  }
`;
