import { FollowType, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { EntityFollowMenu } from "~/components/home/entity-follow-menu";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { greaterBayAreaQuery$key } from "~/queries/__generated__/greaterBayAreaQuery.graphql";

import {
  Container,
  StyledHomeTopStoriesBlock,
  StyledTopicFollowButton,
  Subsection,
  TopicBadge,
  TopicName,
} from "./styles";

type Props = {
  className?: string;
  reference: greaterBayAreaQuery$key;
};

export const HomeGreaterBayArea: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment greaterBayAreaQuery on Query
      @argumentDefinitions(greaterBayAreaArticlesQueueName: { type: "String!" }) {
        topic(filter: { entityId: "324555" }) {
          ...entityLink
          ...followButtonBase
          name
        }
        greaterBayAreaArticles: queue(filter: { name: $greaterBayAreaArticlesQueueName }) {
          items(first: 4) {
            edges {
              ...topStoriesBlockQueueItemsEdge
            }
          }
        }
        greaterBayAreaTopics: queue(filter: { name: "related_topics_508929" }) {
          items(first: 200) {
            edges {
              ...entityFollowMenuQueueItemsEdge
            }
          }
        }
      }
    `,
    reference_,
  );

  const greaterBayAreaItems = data.greaterBayAreaArticles?.items?.edges ?? [];
  const topicEdges = data.greaterBayAreaTopics?.items?.edges ?? [];

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/homepage_gba",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.HomepageGBA,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <TopicBadge>
          <TopicName reference={data.topic}>{data.topic.name}</TopicName>
          <StyledTopicFollowButton
            reference={data.topic}
            source="Homepage_gba"
            type={FollowType.Topic}
          />
        </TopicBadge>

        <Subsection>
          <EntityFollowMenu reference={topicEdges} />
        </Subsection>
        <StyledHomeTopStoriesBlock
          reference={greaterBayAreaItems}
          onClick={entityId => {
            sendGATracking({
              action: "Click/homepage_gba",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeGreaterBayArea.displayName = "HomeGreaterBayArea";
