/**
 * @generated SignedSource<<7de715bbd5a5c37bcfef831966a8fac1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trendingTopicTopic$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
  readonly " $fragmentType": "trendingTopicTopic";
};
export type trendingTopicTopic$key = {
  readonly " $data"?: trendingTopicTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendingTopicTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendingTopicTopic",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "followButtonBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "432e9fae111c37acdb1bc078e2506c7c";

export default node;
