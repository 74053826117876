import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { thingsToDoQueue$key } from "~/queries/__generated__/thingsToDoQueue.graphql";

import { Container, StyledContentItemHomeSecondary, ThingsToDoItems, Title } from "./styles";
type Props = {
  className?: string;
  reference: thingsToDoQueue$key;
};

export const HomeThingsToDo: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment thingsToDoQueue on Query {
        thingToDoQueue: queue(filter: { name: "homepage_service_journalism_hk" }) {
          settings
          items(first: 3) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const customText =
    (data?.thingToDoQueue?.settings as { custom_text?: string } | undefined)?.custom_text ??
    "THINGS TO DO: HONG KONG";
  const thingsToItems = data?.thingToDoQueue?.items?.edges;

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/things_to_do_hk",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (thingsToItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.ThingsToDo,
      }}
    >
      <Container className={className}>
        <Title>{customText}</Title>
        <ThingsToDoItems ref={captureTrackImpressionEventTargetElement}>
          {thingsToItems?.map(({ node }) => (
            <StyledContentItemHomeSecondary
              key={node.entityId}
              reference={node}
              withImage={true}
              withSection={true}
              onClick={entityId => {
                sendGATracking({
                  action: "Click/things_to_do_hk",
                  category: "Homepage Depth",
                  label: entityId,
                });
              }}
            />
          ))}
        </ThingsToDoItems>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeThingsToDo.displayName = "HomeThingsToDo";
