/**
 * @generated SignedSource<<9d2a704b982889e16d40b4087011d7e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subsectionMenuQueue$data = {
  readonly edges: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"entityFollowMenuQueueItemsEdge">;
  }>;
  readonly " $fragmentType": "subsectionMenuQueue";
};
export type subsectionMenuQueue$key = {
  readonly " $data"?: subsectionMenuQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "subsectionMenuQueue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QueueItemsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityFollowMenuQueueItemsEdge"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemConnection",
  "abstractKey": null
};

(node as any).hash = "1e25f3cc34aed6b174e5078daea18a83";

export default node;
