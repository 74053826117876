import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { HomeTopStoriesBlock } from "~/components/home/top-stories/top-stories-block";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { spotlightQuery$key } from "~/queries/__generated__/spotlightQuery.graphql";

import { Container, Description, LogoContainer, StyledLogo } from "./styles";

type Props = {
  className?: string;
  reference: spotlightQuery$key;
};

type Settings = {
  custom_text?: string;
};

export const HomeSpotlight: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const { spotlightQueue } = useFragment(
    graphql`
      fragment spotlightQuery on Query
      @argumentDefinitions(spotlightQueueName: { type: "String!" }) {
        spotlightQueue: queue(filter: { name: $spotlightQueueName }) {
          settings
          items(first: 4) {
            edges {
              ...topStoriesBlockQueueItemsEdge
            }
          }
        }
      }
    `,
    reference_,
  );

  const spotlightItems = spotlightQueue?.items?.edges ?? [];
  const customText = (spotlightQueue?.settings as Settings)?.custom_text ?? "";

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/scmp_spotlight",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Spotlight,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <LogoContainer>
          <StyledLogo />
          <Description>{customText}</Description>
        </LogoContainer>
        <HomeTopStoriesBlock
          reference={spotlightItems}
          onClick={entityId => {
            sendGATracking({
              action: "Click/scmp_spotlight",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeSpotlight.displayName = "HomeSpotlight";
