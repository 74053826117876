/**
 * @generated SignedSource<<4b483d8079f9abb84eca6da301d82aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type followButtonBase$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityFollowButtonBase">;
  readonly " $fragmentType": "followButtonBase";
};
export type followButtonBase$key = {
  readonly " $data"?: followButtonBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "followButtonBase",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityFollowButtonBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Base",
  "abstractKey": "__isBase"
};

(node as any).hash = "5a66263342cf876b852974b951ba8cca";

export default node;
