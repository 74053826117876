/* eslint-disable max-lines */
import { notEmpty, scmpPlus, theme } from "@product/scmp-sdk";
import enc from "crypto-js/enc-hex";
import SHA256 from "crypto-js/sha256";
import type { PageProps } from "next";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { graphql, usePreloadedQuery } from "react-relay";
import { withRelay } from "relay-nextjs";
import type { RelayComponent } from "relay-nextjs";

import { AdSlot } from "~/components/advertisement/ad-slots/ad-slot";
import {
  TargetingHomePageTakeOverType,
  TargetingPaidType,
} from "~/components/advertisement/ad-slots/types";
import { TakeoverAdSlot } from "~/components/advertisement/takeover-ad-slot";
import { useAppBarAdSlot } from "~/components/app-bar/hooks";
import { AppFooter } from "~/components/app-footer";
import { ClientSideSuspense } from "~/components/common/client-side-suspense";
import { HighlightedHeadlineEffectiveArea } from "~/components/content/content-item-render/contexts";
import { FallbackLoading } from "~/components/fallback-loading";
import { HomeAdvertisers } from "~/components/home/advertisers";
import { AiArticleList } from "~/components/home/ai-article-list";
import { HomeAround } from "~/components/home/around";
import { HomeClientQueryLoader } from "~/components/home/client-query-loader";
import { HomeComment } from "~/components/home/comment";
import { HomeFocusArticle } from "~/components/home/focus-article";
import { HomeGreaterBayArea } from "~/components/home/greater-bay-area";
import { HomeHeroArticle } from "~/components/home/hero-article";
import { HomeHeroThreeArticles } from "~/components/home/hero-three-articles";
import { HomeLatest } from "~/components/home/latest";
import { HomeMostPopular } from "~/components/home/most-popular";
import { HomeMultimediaItems } from "~/components/home/multimedia";
import { MyNewsDailyFive } from "~/components/home/my-news-daily-five";
import { HomeNewsletter } from "~/components/home/newsletter";
import { HomeSectionTop } from "~/components/home/section-top";
import { HomeSeo } from "~/components/home/seo";
import { HomeSpotlight } from "~/components/home/spotlight";
import { HomeStoryPackage } from "~/components/home/story-package";
import { HomeSubscriptionWidget } from "~/components/home/subscription-widget";
import { HomeSuperSplash } from "~/components/home/super-splash";
import { HomeThingsToDo } from "~/components/home/things-to-do";
import { HomeTopStories } from "~/components/home/top-stories";
import { section as sectionData } from "~/data/section";
import { AiEngineDataType } from "~/lib/ai-engine/enums";
import { Edition } from "~/lib/edition";
import { createClientEnvironment } from "~/lib/relay/environment.client";
import { parseQueryString } from "~/lib/utils";
import type {
  homePageQuery,
  LiveWidgetEditionEnum,
} from "~/queries/__generated__/homePageQuery.graphql";

import { DesktopMaximumAdHeight } from "./consts";
import {
  useAutoRefreshWhenIdlingForTenMinutes,
  useGetEntityIdsByQueueItems,
  useLayoutWorkaroundForMyNews,
  usePickMorningStudioArticles,
  useWhyRegisterHomepagePositionTriggerReference,
} from "./hooks";
import {
  AdContainer,
  AroundScmp,
  Comment,
  Container,
  ContentContainer,
  ContentWrapper,
  EighthAds,
  FifthAds,
  FifthSectionBlock,
  FirstAds,
  FirstSectionBlock,
  Focus,
  FourthAds,
  FourthSectionBlock,
  FromOurAdvertisers,
  HeroArticles,
  HeroThreeArticles,
  IpuNewsletter,
  Latest,
  MostPopular,
  Multimedia,
  MyNews,
  Newsletters,
  NinthAds,
  RecommendedForYou,
  SecondAds,
  SecondSectionBlock,
  SectionBlocksAndSpotlight,
  SeventhAds,
  SixthAds,
  Subscription,
  ThingsToDo,
  ThirdAds,
  ThirdSectionBlock,
  TopStories,
  YouMayHaveMissed,
} from "./styles";

const query = graphql`
  query homePageQuery(
    $commentHarrySectionIds: [String]
    $commentQueueName: String!
    $focusQueueName: String!
    $liveWidgetEditionEnumValue: LiveWidgetEditionEnum!
    $morningStudioQueueName: String!
    $scmpPlusPaywallTypeIds: [String]
    $spotlightQueueName: String!
    $storyPackageArticlesQueueName: String!
    $topStoriesQueueName: String!
    $greaterBayAreaArticlesQueueName: String!
  ) {
    topStories: queue(filter: { name: $topStoriesQueueName }) {
      items(first: 18) {
        edges {
          ...heroThreeArticlesQueueItemsEdge
          ...topStoriesQueueItemsEdge
          node {
            ...heroArticleArticle
          }
        }
      }
    }
    morningStudioQueue: queue(filter: { name: $morningStudioQueueName }) {
      ...hooksPickMorningStudioArticle
    }
    ...newsletterHomeNewsletterQuery
    ...latestContentsQuery @arguments(scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds)
    ...thingsToDoQueue
    ...commentQueueQuery
      @arguments(
        commentQueueName: $commentQueueName
        commentHarrySectionIds: $commentHarrySectionIds
        scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
      )
    ...storyPackageQuery @arguments(storyPackageArticlesQueueName: $storyPackageArticlesQueueName)
    ...spotlightQuery @arguments(spotlightQueueName: $spotlightQueueName)
    ...greaterBayAreaQuery
      @arguments(greaterBayAreaArticlesQueueName: $greaterBayAreaArticlesQueueName)
    ...focusArticleHomeFocusArticleQuery @arguments(focusQueueName: $focusQueueName)
    ...superSplashQuery @arguments(edition: $liveWidgetEditionEnumValue)

    commentQueue: queue(filter: { name: $commentQueueName }) {
      items(first: 5) {
        edges {
          ...hooksGetEntityIdsByQueueItemQueueItemsEdge
        }
      }
    }
    topStories: queue(filter: { name: $topStoriesQueueName }) {
      items(first: 18) {
        edges {
          ...hooksGetEntityIdsByQueueItemQueueItemsEdge
        }
      }
    }
    storyPackageArticles: queue(filter: { name: $storyPackageArticlesQueueName }) {
      items(first: 3) {
        edges {
          ...hooksGetEntityIdsByQueueItemQueueItemsEdge
        }
      }
    }
    spotlightQueue: queue(filter: { name: $spotlightQueueName }) {
      items(first: 4) {
        edges {
          ...hooksGetEntityIdsByQueueItemQueueItemsEdge
        }
      }
    }
    greaterBayAreaArticles: queue(filter: { name: $greaterBayAreaArticlesQueueName }) {
      items(first: 4) {
        edges {
          ...hooksGetEntityIdsByQueueItemQueueItemsEdge
        }
      }
    }
  }
`;

const HomePage: RelayComponent<PageProps, homePageQuery> = ({ preloadedQuery, randomSeed }) => {
  const { query: routeQuery } = useRouter();
  const edition = routeQuery.edition as Edition;
  const isAsiaEdition = edition === Edition.Asia;
  const data = usePreloadedQuery(query, preloadedQuery);

  const adZone = useMemo(() => `homepage_${edition}/home`, [edition]);

  useAppBarAdSlot({
    desktop: {
      adUnit: "d_banner1",
      targeting: {
        hpto: TargetingHomePageTakeOverType.Top,
        paid: TargetingPaidType.Free,
      },
      zone: adZone,
    },
    mobile: {
      adUnit: "m_banner3",
      targeting: {
        hpto: TargetingHomePageTakeOverType.Top,
        paid: TargetingPaidType.Free,
      },
      zone: adZone,
    },
  });

  useAutoRefreshWhenIdlingForTenMinutes();

  const [
    heroArticle,
    heroThreeArticle1,
    heroThreeArticle2,
    heroThreeArticle3,
    topStoryArticle1,
    topStoryArticle2,
    topStoryArticle3,
    ...rest
  ] = data?.topStories?.items?.edges?.filter(item => notEmpty(item)) ?? [];

  const [morningStudioArticleSlot1, morningStudioArticleSlot2] = usePickMorningStudioArticles(
    randomSeed ?? "fallback seed",
    data.morningStudioQueue,
  );

  const heroThreeArticles = [heroThreeArticle1, heroThreeArticle2, heroThreeArticle3];

  const topStories = [
    topStoryArticle1,
    topStoryArticle2,
    topStoryArticle3,
    morningStudioArticleSlot1,
    ...rest,
  ];

  const { isSecondSectionHigherThanMyNews, setMyNewsElement, setSecondSectionBlockElement } =
    useLayoutWorkaroundForMyNews(edition);

  const whyRegisterHomepagePositionTriggerReference =
    useWhyRegisterHomepagePositionTriggerReference();

  const ssrContentEntityIds = useGetEntityIdsByQueueItems([
    ...(data.spotlightQueue?.items?.edges ?? []),
    ...(data.commentQueue?.items?.edges ?? []),
    ...(data.topStories?.items?.edges ?? []),
    ...(data.storyPackageArticles?.items?.edges ?? []),
    ...(data.greaterBayAreaArticles?.items?.edges ?? []),
  ]);

  return (
    <HighlightedHeadlineEffectiveArea>
      <HomeSeo edition={edition} />
      <Container>
        <ContentContainer>
          <HomeSuperSplash reference={data} />
          <ContentWrapper
            $edition={edition}
            $isSecondSectionHigherThanMyNews={isSecondSectionHigherThanMyNews}
          >
            <IpuNewsletter>
              <AdSlot
                adUnit="d_banner3"
                breakpoint={theme.breakpoints.up("homeDesktop")}
                sizes={[[300, 50]]}
                targeting={{
                  paid: TargetingPaidType.Free,
                }}
                zone={adZone}
              />
            </IpuNewsletter>
            <HeroArticles>
              {heroArticle && <HomeHeroArticle reference={heroArticle.node} />}
            </HeroArticles>
            <FirstAds>
              <AdContainer>
                <AdSlot
                  adUnit="d_lrec1"
                  breakpoint={theme.breakpoints.up("tablet")}
                  sizes={[[300, 250], "fluid"]}
                  targeting={{
                    paid: TargetingPaidType.Free,
                  }}
                  zone={adZone}
                  withLabel
                />
                <AdSlot
                  adUnit="m_banner1"
                  breakpoint={theme.breakpoints.only("mobile")}
                  sizes={[[300, 50], [320, 50], [300, 100], [320, 100], [300, 250], "fluid"]}
                  targeting={{
                    paid: TargetingPaidType.Free,
                  }}
                  zone={adZone}
                  withLabel
                />
              </AdContainer>
            </FirstAds>
            <HeroThreeArticles>
              {heroThreeArticles.length > 0 && (
                <HomeHeroThreeArticles reference={heroThreeArticles} />
              )}
            </HeroThreeArticles>
            <Focus>
              <HomeFocusArticle reference={data} />
            </Focus>
            <TopStories>
              <HomeTopStories adZone={adZone} reference={topStories} />
            </TopStories>
            <Comment>
              <HomeComment reference={data} />
            </Comment>
            <Latest>
              <HomeLatest reference={data} />
            </Latest>
            <ThingsToDo $edition={edition}>
              {edition === Edition.HongKong && <HomeThingsToDo reference={data} />}
            </ThingsToDo>
            <SecondAds>
              <AdSlot
                adUnit="d_lrec3"
                breakpoint={theme.breakpoints.up("tablet")}
                sizes={[[300, 250], [300, DesktopMaximumAdHeight], "fluid"]}
                stickySpacing={60}
                targeting={{
                  paid: TargetingPaidType.Free,
                }}
                zone={adZone}
                stickyLabel
                withLabel
                withSticky
              />
            </SecondAds>
            <ThirdAds>
              <AdSlot
                adUnit="d_lrec3a"
                breakpoint={theme.breakpoints.up("tablet")}
                sizes={[[300, 250], [300, DesktopMaximumAdHeight], "fluid"]}
                stickySpacing={60}
                targeting={{
                  paid: TargetingPaidType.Free,
                }}
                zone={adZone}
                stickyLabel
                withLabel
                withSticky
              />
            </ThirdAds>
            <Newsletters>
              <HomeNewsletter reference={data} />
            </Newsletters>
            <SectionBlocksAndSpotlight>
              <HomeStoryPackage reference={data} />
              <HomeGreaterBayArea reference={data} />
              <div ref={whyRegisterHomepagePositionTriggerReference}>
                <HomeSpotlight reference={data} />
              </div>
            </SectionBlocksAndSpotlight>
            <FourthAds>
              <AdContainer>
                <AdSlot
                  adUnit="d_lrec2"
                  breakpoint={theme.breakpoints.up("tablet")}
                  sizes={[[300, 250], "fluid"]}
                  targeting={{
                    paid: TargetingPaidType.Free,
                  }}
                  zone={adZone}
                  withLabel
                />
                <AdSlot
                  adUnit="m_lrec3"
                  breakpoint={theme.breakpoints.only("mobile")}
                  sizes={[[300, 250], [320, 480], "fluid"]}
                  targeting={{
                    paid: TargetingPaidType.Free,
                  }}
                  zone={adZone}
                  withLabel
                />
              </AdContainer>
            </FourthAds>

            <ErrorBoundary fallback={<AppFooter variant="home" />}>
              {/* to prevent CLS of AI article widget, we hide all the components below it and show them together when loaded */}
              <ClientSideSuspense fallback={<></>}>
                <HomeClientQueryLoader ssrContentEntityIds={ssrContentEntityIds}>
                  {({ data, entityIds: { aiWidget, existing } }) => (
                    <>
                      <RecommendedForYou>
                        <AiArticleList
                          entityIds={aiWidget[AiEngineDataType.HomeRecommendedForYou]}
                          sponsoredReference={morningStudioArticleSlot2.node}
                          variant={AiEngineDataType.HomeRecommendedForYou}
                        />
                      </RecommendedForYou>
                      <FirstSectionBlock>
                        {isAsiaEdition ? (
                          <HomeSectionTop reference={data} section="twia" />
                        ) : (
                          <HomeSectionTop reference={data} section="hong-kong" />
                        )}
                      </FirstSectionBlock>

                      <Multimedia>
                        {data.multimedia?.items && (
                          <HomeMultimediaItems reference={data.multimedia?.items} />
                        )}
                      </Multimedia>
                      <Subscription>
                        <HomeSubscriptionWidget />
                      </Subscription>
                      <SecondSectionBlock>
                        {isAsiaEdition ? (
                          <HomeSectionTop
                            ref={element => setSecondSectionBlockElement?.(element)}
                            reference={data}
                            section="people-culture"
                          />
                        ) : (
                          <HomeSectionTop
                            ref={element => setSecondSectionBlockElement?.(element)}
                            reference={data}
                            section="lifestyle"
                          />
                        )}
                      </SecondSectionBlock>
                      <YouMayHaveMissed>
                        <AiArticleList
                          entityIds={aiWidget[AiEngineDataType.HomeYouMayHaveMissed]}
                          variant={AiEngineDataType.HomeYouMayHaveMissed}
                        />
                      </YouMayHaveMissed>
                      <MyNews>
                        <MyNewsDailyFive ref={element => setMyNewsElement?.(element)} />
                      </MyNews>
                      <ThirdSectionBlock>
                        <HomeSectionTop reference={data} section="china" />
                      </ThirdSectionBlock>
                      <FourthSectionBlock>
                        {isAsiaEdition ? (
                          <HomeSectionTop reference={data} section="lifestyle" />
                        ) : (
                          <HomeSectionTop reference={data} section="people-culture" />
                        )}
                      </FourthSectionBlock>
                      <FifthSectionBlock>
                        <HomeSectionTop reference={data} section="style" />
                      </FifthSectionBlock>
                      <MostPopular>
                        <HomeMostPopular reference={data} />
                      </MostPopular>
                      <FromOurAdvertisers>
                        {data?.advertisers?.items && (
                          <HomeAdvertisers reference={data?.advertisers?.items} />
                        )}
                      </FromOurAdvertisers>
                      {/* For preventing AroundScmp is too slow and blocking the rendering  */}
                      <ClientSideSuspense>
                        <AroundScmp>
                          <HomeAround existingEntityIds={existing} />
                        </AroundScmp>
                        <AppFooter variant="home" />
                      </ClientSideSuspense>
                    </>
                  )}
                </HomeClientQueryLoader>
                <FifthAds>
                  <AdContainer>
                    <AdSlot
                      adUnit="d_lrec4"
                      breakpoint={theme.breakpoints.up("tablet")}
                      sizes={[[300, 250], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                    <AdSlot
                      adUnit="m_lrec3c"
                      breakpoint={theme.breakpoints.only("mobile")}
                      sizes={[[300, 250], [320, 480], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                  </AdContainer>
                </FifthAds>
                <SixthAds>
                  <AdContainer>
                    <AdSlot
                      adUnit="d_lrec4a"
                      breakpoint={theme.breakpoints.up("tablet")}
                      sizes={[[300, 250], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                    <AdSlot
                      adUnit="m_lrec2"
                      breakpoint={theme.breakpoints.only("mobile")}
                      sizes={[[300, 250], [320, 480], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                  </AdContainer>
                </SixthAds>
                <SeventhAds>
                  <AdContainer>
                    <AdSlot
                      adUnit="d_lrec4d"
                      breakpoint={theme.breakpoints.up("tablet")}
                      sizes={[[300, 250], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                    <AdSlot
                      adUnit="m_lrec3g"
                      breakpoint={theme.breakpoints.only("mobile")}
                      sizes={[[300, 250], [320, 480], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                  </AdContainer>
                </SeventhAds>
                <EighthAds>
                  <AdContainer>
                    <AdSlot
                      adUnit="d_lrec4b"
                      breakpoint={theme.breakpoints.up("tablet")}
                      sizes={[[300, 250], "fluid"]}
                      targeting={{
                        paid: TargetingPaidType.Free,
                      }}
                      zone={adZone}
                      withLabel
                    />
                  </AdContainer>
                </EighthAds>
                <NinthAds>
                  <AdSlot
                    adUnit="m_lrec3h"
                    breakpoint={theme.breakpoints.only("mobile")}
                    sizes={[[300, 250], [320, 480], "fluid"]}
                    targeting={{
                      paid: TargetingPaidType.Free,
                    }}
                    zone={adZone}
                    withLabel
                  />
                </NinthAds>
                <TakeoverAdSlot adSlotProps={{ zone: adZone }} variant="home" />
              </ClientSideSuspense>
            </ErrorBoundary>
          </ContentWrapper>
        </ContentContainer>
      </Container>
    </HighlightedHeadlineEffectiveArea>
  );
};

HomePage.displayName = "HomePage";

const pageProps: PageProps = {
  appBarConfiguration: {
    desktopBreakpoint: "homeDesktop",
    hasDesktopAd: true,
    hasMobileAd: true,
    variant: "scmp/home",
  },
};

const generateRandomSeed = () => SHA256(new Date().toString()).toString(enc);

export default withRelay(HomePage, query, {
  clientSideProps: () => ({ ...pageProps, randomSeed: generateRandomSeed() }),
  createClientEnvironment: () => createClientEnvironment(),
  createServerEnvironment: async context => {
    const { createServerEnvironment } = await import("~/lib/relay/environment.server");
    return createServerEnvironment(context.req?.cookies);
  },
  fallback: <FallbackLoading />,
  serverSideProps: () => Promise.resolve({ ...pageProps, randomSeed: generateRandomSeed() }),
  variablesFromContext(context) {
    const edition = parseQueryString(context.query.edition) ?? "int";
    const liveWidgetEditionEnumValue: LiveWidgetEditionEnum =
      edition.toLocaleUpperCase() as Uppercase<Edition>;
    return {
      commentHarrySectionIds: [sectionData.comment.harrysView.entityUuid],
      commentQueueName: `opinion_top_${edition}`,
      edition,
      focusQueueName: `homepage_focus_${edition}`,
      greaterBayAreaArticlesQueueName: `homepage_gba_${edition}`,
      liveWidgetEditionEnumValue,
      morningStudioQueueName: `homepage_morning_studio_${edition}`,
      scmpPlusPaywallTypeIds: [scmpPlus?.article?.paywallTypeId],
      spotlightQueueName: `scmp_signatures_top_${edition}`,
      storyPackageArticlesQueueName: `homepage_package_top_${edition}`,
      topStoriesQueueName: `homepage_top_${edition}`,
    };
  },
});
