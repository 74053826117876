import {
  HomepageThemeFindOurMoreDisplay,
  HomepageThemeSignUpButtonDisplay,
  NewsletterVariant,
  notEmpty,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { massageToNewsletterItem } from "~/components/newsletter/helpers";
import { NewsletterWidgetContainer } from "~/components/newsletter/newsletter-widget-container";
import { useNewsletterWidget } from "~/components/newsletter/newsletter-widget-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import type { newsletterHomeNewsletterQuery$key } from "~/queries/__generated__/newsletterHomeNewsletterQuery.graphql";

export type Props = {
  className?: string;
  reference: newsletterHomeNewsletterQuery$key;
};

export const HomeNewsletter: FunctionComponent<Props> = ({ className, reference }) => {
  const query = useFragment(
    graphql`
      fragment newsletterHomeNewsletterQuery on Query {
        chinaAtAGlanceNewsletter: newsletter(filter: { entityId: "322279" }) {
          ...helpersMassageToNewsletterItemNewsletter
        }
        hongKongUpdateNewsletter: newsletter(filter: { entityId: "510083" }) {
          ...helpersMassageToNewsletterItemNewsletter
        }
        todayNewsletter: newsletter(filter: { entityId: "322277" }) {
          ...helpersMassageToNewsletterItemNewsletter
        }
      }
    `,
    reference,
  );

  const newsletterItems = useMemo(() => {
    const chinaAtAGlanceNewsletter =
      query.chinaAtAGlanceNewsletter && massageToNewsletterItem(query.chinaAtAGlanceNewsletter);
    const hongkongUpdateNewsletter =
      query.hongKongUpdateNewsletter && massageToNewsletterItem(query.hongKongUpdateNewsletter);
    const todayNewsletter = query.todayNewsletter && massageToNewsletterItem(query.todayNewsletter);
    return [todayNewsletter, chinaAtAGlanceNewsletter, hongkongUpdateNewsletter].filter(notEmpty);
  }, [query.chinaAtAGlanceNewsletter, query.hongKongUpdateNewsletter, query.todayNewsletter]);

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Newsletter Sign Up/Impression",
      category: "Newsletter (Homepage - Inline)",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const { dataMatrix } = useNewsletterWidget({
    contentType: "homepage",
    eventCategory: "Newsletter (Homepage - Inline)",
    section: "",
  });

  return (
    <div ref={captureTrackImpressionEventTargetElement}>
      <NewsletterWidgetContainer
        className={className}
        dataMatrix={dataMatrix}
        findOutMoreButtonDisplayComponent={<HomepageThemeFindOurMoreDisplay />}
        newsletterItems={newsletterItems}
        signUpButtonDisplayComponent={<HomepageThemeSignUpButtonDisplay />}
        variant={NewsletterVariant.Homepage}
      />
    </div>
  );
};

HomeNewsletter.displayName = "HomeNewsletter";
