/**
 * @generated SignedSource<<f96d4658e956eefc1ce44f1749053903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeAroundSecondaryContentItemContent$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeAroundSecondaryContentItemContent";
};
export type homeAroundSecondaryContentItemContent$key = {
  readonly " $data"?: homeAroundSecondaryContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeAroundSecondaryContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeAroundSecondaryContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "97feca8b381d6a4f1acb769ccb98379c";

export default node;
