import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import { pathname as livePathName } from "~/pages/live";
import type { latestContentsQuery$key } from "~/queries/__generated__/latestContentsQuery.graphql";

import { Container, LatestItems, MoreLink, StyledContentItemHomeLatest, Title } from "./styles";

type Props = {
  className?: string;
  reference: latestContentsQuery$key;
};

export const HomeLatest: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const latestData = useFragment(
    graphql`
      fragment latestContentsQuery on Query
      @argumentDefinitions(
        latestExcludeArticleType: { type: "[String]" }
        latestExcludeSectionIds: { type: "[String]" }
        scmpPlusPaywallTypeIds: { type: "[String]" }
      ) {
        contents(
          contentTypes: [ARTICLE, GALLERY, VIDEO]
          first: 6
          exclude: { sectionIds: $latestExcludeSectionIds }
          articleExcludeInput: {
            articleTypeIds: $latestExcludeArticleType
            paywallTypeIds: $scmpPlusPaywallTypeIds
          }
          articleFilterInput: { sponsorTypes: [NONE] }
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) {
          edges {
            node {
              entityId
              ...homeLatestContentItemContent
            }
          }
        }
      }
    `,
    reference_,
  );

  const latestItems = latestData.contents.edges;

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/latest",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  if (latestItems.length === 0) return null;
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Latest,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title pathname={livePathName}>LATEST</Title>
        <LatestItems>
          {latestItems.map(({ node }) => (
            <StyledContentItemHomeLatest
              key={node.entityId}
              reference={node}
              onClick={entityId => {
                sendGATracking({
                  action: "Click/latest",
                  category: "Homepage Depth",
                  label: entityId,
                });
              }}
            />
          ))}
        </LatestItems>
        <MoreLink
          pathname={livePathName}
          onClick={() => {
            sendGATracking({
              action: "Click/more_latest_news",
              category: "Homepage Depth",
              label: window.location.href,
            });
          }}
        >
          MORE LATEST NEWS
        </MoreLink>
      </Container>
    </BaseLinkContextProvider>
  );
};
