import first from "lodash/first";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { tracking } from "~/data";
import type { multimediaQueue$key } from "~/queries/__generated__/multimediaQueue.graphql";

import {
  Container,
  Content,
  StyledContentItemHomeMultimedia,
  StyledContentItemHomeMultimediaPodcast,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: multimediaQueue$key;
};

const handleClick = (entityId = "") => {
  sendGATracking({
    action: "Click/multimedia",
    category: "Homepage Depth",
    label: entityId,
  });
};

export const HomeMultimediaItems: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const multimedia = useFragment(
    graphql`
      fragment multimediaQueue on QueueItemConnection {
        edges {
          node {
            __typename
            ... on Article {
              types {
                value {
                  name
                }
              }
            }
            ... on Content {
              entityId
              ...homeMultimediaContentItemContent
              ...homeMultimediaPodcastContentItemContent
            }
          }
        }
      }
    `,
    reference_,
  );

  const renderMultimediaItems = () =>
    (multimedia.edges ?? []).map(item => {
      const mainArticleType = first(item?.node?.types);
      const articleTypeName = first(mainArticleType?.value)?.name;
      const isPodcast = item?.node?.__typename === "Podcast" || articleTypeName === "Podcast";
      if (isPodcast)
        return (
          <StyledContentItemHomeMultimediaPodcast
            key={item?.node.entityId}
            reference={item?.node}
            onClick={handleClick}
          />
        );
      return (
        <StyledContentItemHomeMultimedia
          key={item?.node.entityId}
          reference={item?.node}
          onClick={handleClick}
        />
      );
    });
  ``;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Multimedia,
      }}
    >
      <Container className={className}>
        <Title>MULTIMEDIA</Title>
        <Content>{renderMultimediaItems()}</Content>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeMultimediaItems.displayName = "HomeMultimediaItems";
