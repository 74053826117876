import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homeDailyFiveContentItemContent$key } from "~/queries/__generated__/homeDailyFiveContentItemContent.graphql";

import { Container, Headline, StyledEntityLink } from "./styles";

export type Props = {
  reference: homeDailyFiveContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeDailyFiveContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent @arguments(withHeadline: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <Headline>
            {providers.headline({
              hideTooltip: true,
              preferSocialHeadline: true,
            })}
          </Headline>
        </StyledEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemDailyFive";

export const ContentItemDailyFive = withHighlightedHeadline(Component);
