import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { EntityLink } from "~/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledEntityLink = styled(EntityLink)`
  margin-block-end: 12px;

  font-weight: 700;
  font-size: 16px;
  font-family: ${fontMerriweather};
  line-height: 22px;
`;

export const Headline = styled.span`
  color: #33b8b5;
`;

export const DisplayDate = styled.span`
  color: #000000;
`;

export const StyledCoverEntityLink = styled(EntityLink)``;

export const CoverImage = styled.div`
  aspect-ratio: 4/3;
`;
