import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homeCommentContentItemContent$key } from "~/queries/__generated__/homeCommentContentItemContent.graphql";

import {
  ActionBar,
  AuthorImages,
  AuthorLinks,
  Container,
  Content,
  ContentContainer,
  Headline,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: homeCommentContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeCommentContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withAuthorLink: true
              withHeadline: true
              withAuthorImage: true
              withActionBar: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <ContentContainer>
          <Content>
            <AuthorLinks onClick={() => onClick?.(content.entityId)}>
              {providers.authorLink?.({ ignoreAuthorType: true, isMultiple: true })}
            </AuthorLinks>
            <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
              </Headline>
            </StyledEntityLink>
          </Content>
          <AuthorImages>
            {providers.authorImage?.({
              ignoreAuthorType: true,
              isMultiple: true,
              withOutline: true,
            })}
          </AuthorImages>
        </ContentContainer>
        <ActionBar>
          {providers.actionBar({
            commentVariant: "reverse",
            isBookmarkVisible: false,
            isCommentsVisible: true,
            isIconVisible: false,
          })}
        </ActionBar>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeComment";

export const ContentItemHomeComment = withHighlightedHeadline(Component);
