import { FollowType } from "@product/scmp-sdk";
import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homeHeroArticleContentItemContent$key } from "~/queries/__generated__/homeHeroArticleContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  CoverImage,
  Headline,
  LinkHighlightArea,
  MoreOnThis,
  StyledContentHeadline,
  StyledEntityLink,
  StyledHomeFollowButton,
  Summary,
  TopicLink,
} from "./styles";

export type Props = {
  reference: homeHeroArticleContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_, topicLinkVariant }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeHeroArticleContentItemContent on Article {
          entityId
          moreOnThisArticles {
            entityId
            ... on Article {
              ...contentHeadlineContent
              ...entityLink
            }
          }
          topics {
            ...followButtonBase
          }
          ...contentTopicLinkContent
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withLiveTag: true
              withSummary: true
              withActionBar: true
              withTopicLink: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const moreOnThisArticle = first(content.moreOnThisArticles) ?? undefined;
    const topic = first(content.topics) ?? undefined;

    return (
      <Container className={className} ref={reference}>
        <CoverImage>
          <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
              withCaption: true,
            })}
          </StyledEntityLink>
        </CoverImage>

        <Content>
          <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
                {providers.liveTag()}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>

          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: true,
            })}
          </ActionBar>

          {moreOnThisArticle && (
            <MoreOnThis>
              <StyledEntityLink
                reference={moreOnThisArticle}
                onClick={() => onClick?.(moreOnThisArticle.entityId)}
              >
                <StyledContentHeadline
                  hideTag={false}
                  preferSocialHeadline={true}
                  reference={moreOnThisArticle}
                  skipHighlight={true}
                />
              </StyledEntityLink>
            </MoreOnThis>
          )}

          <TopicLink>
            {providers.topicLink?.()}
            {topic && (
              <StyledHomeFollowButton
                reference={topic}
                source="Top Story 1"
                type={FollowType.Topic}
                isLoadingEnabled
              />
            )}
          </TopicLink>
        </Content>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeHeroArticle";

export const ContentItemHomeHeroArticle = withHighlightedHeadline(Component);
