import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { VariantProps } from "~/components/home/section-top/variant/types";
import type { twoSectionTopWidgetQueue$key } from "~/queries/__generated__/twoSectionTopWidgetQueue.graphql";

import {
  Container,
  Primary,
  Secondary,
  StyledContentItemHomeSecondary,
  StyledContentItemHomeSectionLeadSecondary,
} from "./styles";

type Props = {
  className?: string;
  reference: twoSectionTopWidgetQueue$key;
} & VariantProps;

export const SectionTopWidgetTwo: FunctionComponent<Props> = ({
  className,
  onClick,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment twoSectionTopWidgetQueue on Queue
      @argumentDefinitions(excludeEntityIds: { type: "[String]" }) {
        variantTwoItems: items(first: 5, exclude: { entityIds: $excludeEntityIds }) {
          edges {
            node {
              ... on Content {
                ...entityLink
                ...homeSecondaryContentItemContent
                ...homeSectionLeadSecondaryContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const items =
    data?.variantTwoItems?.edges?.map(({ node }) => node).filter(node => notEmpty(node)) ?? [];

  if (items.length === 0) return null;

  const [leading, ...rest] = items;

  return (
    <Container className={className}>
      <Primary>
        <StyledContentItemHomeSectionLeadSecondary
          reference={leading}
          withComment={true}
          onClick={entityId => onClick(entityId)}
        />
      </Primary>
      <Secondary>
        {rest?.slice(0, 4)?.map((item, index) => (
          <StyledContentItemHomeSecondary
            key={index}
            reference={item}
            withComment={true}
            onClick={entityId => onClick(entityId)}
          />
        ))}
      </Secondary>
    </Container>
  );
};

SectionTopWidgetTwo.displayName = "SectionTopWidgetTwo";
