import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "~/components/common/base-link";
import { ContentItemHomeStoryPackageLead } from "~/components/content/content-item-render/variants/home-story-package-lead";
import { ContentItemHomeStoryPackageTail } from "~/components/content/content-item-render/variants/home-story-package-tail";
import { EntityLink } from "~/components/entity-link";
import { HomeFollowButton } from "~/components/home/follow-button";

export const StyledContentItemHomeStoryPackageLead = styled(ContentItemHomeStoryPackageLead)``;
export const StyledContentItemHomeStoryPackageTail = styled(ContentItemHomeStoryPackageTail)``;

export const Container = styled.div`
  display: grid;
  grid:
    "topic-name-follow" min-content
    "subsection" min-content
    "leading-article" min-content
    / 100%;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "topic-name-follow subsection" min-content
      "leading-article leading-article" min-content
      / max-content minmax(0, 1fr);
  }
`;

export const TopicBadge = styled.div`
  grid-area: topic-name-follow;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  block-size: 24px;
  margin-block-end: 20px;

  text-transform: uppercase;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const TopicNameEntityLink = styled(EntityLink)``;

export const TopicNameBaseLink = styled(BaseLink)``;

export const TopicName = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 24px;
  white-space: nowrap;
`;

export const StyledTopicFollowButton = styled(HomeFollowButton)`
  margin-inline-start: 12px;
`;

export const Articles = styled.div`
  grid-area: leading-article;
`;
