import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";

import { EntityFollowButton } from "~/components/entity-follow-button";

export const Container = styled.div``;

export const StyledDesktopEntityFollowButton = styled(EntityFollowButton)`
  display: none;

  line-height: 0;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;
  }

  button {
    inline-size: auto;
    padding-block: 0;

    font-family: ${fontRobotoCondensed};
  }
`;

export type StyledMobileEntityFollowButtonProps = {
  $buttonVariant?: "square";
};
export const StyledMobileEntityFollowButton = styled(EntityFollowButton, {
  ...transientOptions,
})<StyledMobileEntityFollowButtonProps>`
  display: block;

  line-height: 0;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }

  button {
    inline-size: fit-content;
    padding-block: 0;

    font-weight: 400;
    font-size: 12px;
    font-family: ${fontRobotoCondensed};
    line-height: 14px;
  }

  ${props => {
    switch (props.$buttonVariant) {
      case "square":
        return css`
          line-height: 0;
        `;
      default:
        return css`
          button {
            min-inline-size: 60px;
            padding-block: 2px;
            padding-inline: 4px;
          }
        `;
    }
  }}
`;
