import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { advertisersQueueItemConnection$key } from "~/queries/__generated__/advertisersQueueItemConnection.graphql";

import {
  Container,
  Content,
  StyledArrowNext,
  StyledArrowPrevious,
  StyledContentItemHomeAdvertiser,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  SwiperWrapper,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: advertisersQueueItemConnection$key;
};

export const HomeAdvertisers: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const multimedia = useFragment(
    graphql`
      fragment advertisersQueueItemConnection on QueueItemConnection {
        edges {
          node {
            ... on Content {
              entityId
              ...homeAdvertiserContentItemContent
            }
          }
        }
      }
    `,
    reference_,
  );

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/advertisers",
      category: "Homepage Depth",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const renderAdvertisersItems = () =>
    (multimedia.edges ?? []).map((item, index) => (
      <StyledSwiperSlide key={index}>
        <StyledContentItemHomeAdvertiser
          key={item?.node.entityId}
          reference={item?.node}
          onClick={entityId => {
            sendGATracking({
              action: "Click/advertisers",
              category: "Homepage Depth",
              label: entityId,
            });
          }}
        />
      </StyledSwiperSlide>
    ));

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.FromOurAdvertises,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>FROM OUR ADVERTISERS</Title>
        <Content>
          <SwiperContainer>
            <SwiperWrapper>
              <StyledSwiper
                breakpoints={{
                  0: {
                    spaceBetween: 32,
                  },
                }}
                freeMode={true}
                modules={[Navigation]}
                navigation={{
                  disabledClass: "card-swiper-button-disabled",
                  nextEl: ".card-swiper-button-next",
                  prevEl: ".card-swiper-button-prev",
                }}
                pagination={false}
                slidesPerGroupAuto={true}
                slidesPerView="auto"
              >
                {renderAdvertisersItems()}
              </StyledSwiper>
              <div className="swiper-button card-swiper-button-prev">
                <StyledArrowPrevious />
              </div>
              <div className="swiper-button card-swiper-button-next">
                <StyledArrowNext />
              </div>
            </SwiperWrapper>
          </SwiperContainer>
        </Content>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeAdvertisers.displayName = "HomeAdvertisers";
