import { notEmpty, scmpPlus, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { section as sectionData } from "~/data/section";
import { AiEngineDataType } from "~/lib/ai-engine/enums";
import type { aiArticleListArticleQuery } from "~/queries/__generated__/aiArticleListArticleQuery.graphql";
import type { aiArticleListSponsoredArticle$key } from "~/queries/__generated__/aiArticleListSponsoredArticle.graphql";

import {
  Container,
  Primary,
  Secondary,
  StyledContentItemHomePrimary,
  StyledContentItemHomeSecondary,
  Tertiary,
  Title,
} from "./styles";

export type Props = {
  className?: string;
  entityIds: string[];
  sponsoredReference?: aiArticleListSponsoredArticle$key;
  variant: AiEngineDataType.HomeRecommendedForYou | AiEngineDataType.HomeYouMayHaveMissed;
};

const title: Record<Props["variant"], string> = {
  [AiEngineDataType.HomeRecommendedForYou]: "Recommended For You",
  [AiEngineDataType.HomeYouMayHaveMissed]: "You May Have Missed",
};

const customQueryParameterModule: Record<Props["variant"], string> = {
  [AiEngineDataType.HomeRecommendedForYou]: "AI_Recommended_for_you",
  [AiEngineDataType.HomeYouMayHaveMissed]: "AI_You_may_have_missed",
};

export const AiArticleList: FunctionComponent<Props> = ({
  className,
  entityIds = [],
  sponsoredReference,
  variant,
}) => {
  const sponsoredArticle = useFragment(
    graphql`
      fragment aiArticleListSponsoredArticle on Article {
        ...homeSecondaryContentItemContent
      }
    `,
    sponsoredReference ?? null,
  );

  const { contents } = useLazyLoadQuery<aiArticleListArticleQuery>(
    graphql`
      query aiArticleListArticleQuery(
        $entityIdCount: Int!
        $entityIds: [String]
        $excludeSectionIds: [String]
        $scmpPlusPaywallTypeIds: [String]
      ) {
        contents(
          articleExcludeInput: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          exclude: { sectionIds: $excludeSectionIds }
          filter: { entityIds: $entityIds }
          first: $entityIdCount
          contentTypes: ARTICLE
        ) {
          edges {
            node {
              ... on Content {
                entityId
                ...homePrimaryContentItemContent
                ...homeSecondaryContentItemContent
              }
            }
          }
        }
      }
    `,
    {
      entityIdCount: entityIds.length,
      entityIds,
      excludeSectionIds: [sectionData.posties.entityUuid, sectionData.yp.entityUuid],
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    {
      fetchPolicy: "store-or-network",
    },
  );

  const widgetName =
    variant === AiEngineDataType.HomeRecommendedForYou
      ? "recommended_for_you"
      : "you_may_have_missed";

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: `Impression/${widgetName}` as const,
      category: "Homepage Depth",
    }),
    [widgetName],
  );

  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const handleClick = useCallback(
    (entityId = "") => {
      if (!entityId) return;

      sendGATracking({
        action: `Click/${widgetName}` as const,
        category: "Homepage Depth",
        label: entityId,
      });
    },
    [widgetName],
  );

  const articleMap = useMemo(
    () => Object.fromEntries(contents?.edges?.map(({ node }) => [node.entityId, node])),
    [contents],
  );

  const [primaryArticle, secondaryArticle, ...tertiaryArticles] =
    entityIds
      .map(entityId => articleMap[entityId])
      .filter(node => notEmpty(node))
      .slice(0, sponsoredArticle ? 5 : 6) ?? [];

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: `${customQueryParameterModule[variant]}_In-house`,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>{title[variant]}</Title>
        <Primary>
          {primaryArticle && (
            <StyledContentItemHomePrimary
              reference={primaryArticle}
              withComment={true}
              onClick={entityId => handleClick(entityId)}
            />
          )}
        </Primary>
        <Secondary>
          {secondaryArticle && (
            <StyledContentItemHomeSecondary
              reference={secondaryArticle}
              withComment={true}
              withSection={true}
              withSummary={true}
              onClick={entityId => handleClick(entityId)}
            />
          )}
        </Secondary>
        <Tertiary>
          {tertiaryArticles?.length > 0 &&
            tertiaryArticles.map(node => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                reference={node}
                withComment={true}
                withSection={true}
                onClick={entityId => handleClick(entityId)}
              />
            ))}
          {sponsoredArticle && (
            <StyledContentItemHomeSecondary
              reference={sponsoredArticle}
              withComment={true}
              withImage={true}
              withSection={true}
              onClick={entityId => handleClick(entityId)}
            />
          )}
        </Tertiary>
      </Container>
    </BaseLinkContextProvider>
  );
};

AiArticleList.displayName = "AiArticleList";
