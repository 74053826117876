/**
 * @generated SignedSource<<48c9ef3a08cd477271fcc2e6898b5bd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type greaterBayAreaQuery$data = {
  readonly greaterBayAreaArticles: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly greaterBayAreaTopics: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"entityFollowMenuQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly topic: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
  };
  readonly " $fragmentType": "greaterBayAreaQuery";
};
export type greaterBayAreaQuery$key = {
  readonly " $data"?: greaterBayAreaQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"greaterBayAreaQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "greaterBayAreaArticlesQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "greaterBayAreaQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "324555"
          }
        }
      ],
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "followButtonBase"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": "topic(filter:{\"entityId\":\"324555\"})"
    },
    {
      "alias": "greaterBayAreaArticles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "greaterBayAreaArticlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesBlockQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "greaterBayAreaTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "related_topics_508929"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 200
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "entityFollowMenuQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:200)"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"related_topics_508929\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4bb7b18ff57b280a4ffc2e7bb5d84bb0";

export default node;
