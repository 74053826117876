import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { FlexContainer } from "~/components/content/content-action-bar/styles";
import { StyledFigcaption } from "~/components/content/content-cover-image/styles";
import { ContentHeadline } from "~/components/content/content-headline";
import { StyledTopicLink } from "~/components/content/content-topic-link/styles";
import { EntityLink } from "~/components/entity-link";
import { HomeFollowButton } from "~/components/home/follow-button";
import {
  StyledDesktopEntityFollowButton,
  StyledMobileEntityFollowButton,
} from "~/components/home/follow-button/styles";

export const StyledContentHeadline = styled(ContentHeadline)``;
export const StyledEntityLink = styled(EntityLink)`
  display: block;
`;
export const StyledHomeFollowButton = styled(HomeFollowButton)`
  ${StyledMobileEntityFollowButton} {
    display: block;
  }
  ${StyledDesktopEntityFollowButton} {
    display: none;
  }
`;

export const LinkHighlightArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Container = styled.div`
  display: grid;
  grid:
    "cover-image" min-content
    "content" min-content
    / 100%;
  row-gap: 12px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid: "content cover-image" min-content;
    grid-template-columns: minmax(260px, 338px) minmax(400px, 590px);

    column-gap: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid-template-columns: minmax(220px, 336px) minmax(400px, 550px);

    column-gap: 24px;
  }
`;

export const Content = styled.div`
  grid-area: content;
`;

export const CoverImage = styled.div`
  ${StyledFigcaption} {
    display: none;

    line-height: 14px;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    ${StyledFigcaption} {
      display: block;
    }
  }
`;

export const Headline = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: flex-start;

  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 140%;
  text-align: start;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 22px;
  }
`;

export const Summary = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 130%;
  text-align: start;
`;

export const ActionBar = styled.div`
  margin-block-start: 12px;
  ${FlexContainer} {
    color: #999999;
    font-size: 12px;
  }
`;

export const MoreOnThis = styled.div`
  display: none;

  margin-block-start: 4px;
  padding-block-start: 16px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;

    margin-block-start: 20px;
    padding-block-start: 20px;
  }

  ${StyledContentHeadline} {
    font-weight: 700;
    font-size: 15px;
    font-family: ${fontMerriweather};
    line-height: 140%;
    text-align: start;
  }
`;

export const TopicLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-block-start: 20px;
  padding-block-start: 9px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block-start: 8px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block-start: 9px;
  }

  ${StyledTopicLink} {
    font-weight: 700;
    font-size: 14px;
    text-align: start;
  }

  ${StyledHomeFollowButton} {
    button {
      padding-block: 2px;
      padding-inline: 4px;

      font-weight: 400;
      font-size: 12px;
      font-family: ${fontRobotoCondensed};
    }
  }
`;
