import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { Container as AdsContainer } from "~/components/advertisement/ad-slots/ad-slot/styles";
import { SponsorHeadline } from "~/components/content/content-headline/styles";
import { Headline as PrimaryHeadline } from "~/components/content/content-item-render/variants/home-primary/styles";
import { Headline as SecondaryHeadline } from "~/components/content/content-item-render/variants/home-secondary/styles";

import { TopStoriesItem } from "./top-stories-item";

export const StyledTopStoriesItem = styled(TopStoriesItem)`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.up("tablet")} {
    :last-child {
      margin-block-end: 0;
      padding-block-end: 0;
      border-block-end: none;
    }
  }
`;

export type LeftContainerStyledProps = {
  $isShowMLrecAd: boolean;
};

export const LeftContainer = styled.div<LeftContainerStyledProps>`
  grid-area: left;

  ${theme.breakpoints.up("tablet")} {
    ${PrimaryHeadline} {
      font-size: 22px;
    }
  }

  ${StyledTopStoriesItem}:first-child {
    ${props =>
      props.$isShowMLrecAd &&
      css`
        margin-block-end: 32px;
        ${theme.breakpoints.up("tablet")} {
          margin-block-end: 20px;
        }
      `}
  }

  ${StyledTopStoriesItem}:last-child {
    ${props =>
      props.$isShowMLrecAd &&
      css`
        margin-block-start: 20px;
        padding-block-start: 20px;
        border-block-start: 1px solid rgba(0, 0, 0, 0.1);
        ${theme.breakpoints.up("tablet")} {
          margin-block-start: 0;
          padding-block-start: 0;
          border-block-start: none;
        }
      `}
  }
`;

export const RightContainer = styled.div`
  grid-area: right;

  ${StyledTopStoriesItem}:last-child {
    margin-block-end: 0;
    padding-block-end: 0;
    border-block-end: none;
  }

  ${theme.breakpoints.up("tablet")} {
    ${SecondaryHeadline} {
      font-size: 15px;
      line-height: 140%;
    }
    ${SponsorHeadline} {
      font-size: 16px;
      line-height: 140%;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid:
    "left" min-content
    "right" min-content
    / 100%;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "left right" min-content
      / 1fr calc((100% - 64px) / 3);
    gap: 32px;
  }

  &:not(:last-child) {
    margin-block-end: 20px;
    padding-block-end: 20px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.2);

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: 32px;
      padding-block-end: 32px;
    }
  }
`;

export const AdsSlotContainer = styled.div`
  ${AdsContainer} {
    block-size: auto;
  }
`;

export const MLrecAdContainer = styled(AdsSlotContainer)`
  margin-block-end: 32px;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 0;
  }
`;
