import dynamic from "next/dynamic";

import type { Props as HomeFooterProps } from "~/components/home/footer";

export const DynamicPostiesFooter = dynamic<Record<string, never>>(() =>
  import("~/components/posties/footer").then(module => module.PostiesFooter),
);

export const DynamicStyleFooter = dynamic<Record<string, never>>(() =>
  import("~/components/section/section-style/footer").then(module => module.StyleFooter),
);

export const DynamicHomeFooter = dynamic<HomeFooterProps>(() =>
  import("~/components/home/footer").then(module => module.HomeFooter),
);
