/**
 * @generated SignedSource<<8ea389d76cd5310c1c1791026cf220fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeSecondaryContentItemContent$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent" | "hooksUseContentSponsorContent">;
  readonly " $fragmentType": "homeSecondaryContentItemContent";
};
export type homeSecondaryContentItemContent$key = {
  readonly " $data"?: homeSecondaryContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeSecondaryContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseContentSponsorContent"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withLiveTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSections",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSponsorTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSubHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSummary",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "56e4eefb64da8d93af17f8542aa3b427";

export default node;
