import type { FunctionComponent } from "react";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { config } from "~/data";

import { Container, StyledLogoFlag, SubscribeNowButton, TrialText } from "./styles";

export type Props = {
  className?: string;
};

export const HomeSubscriptionWidget: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    <StyledLogoFlag />
    <TrialText>Help preserve 120 years of quality journalism.</TrialText>
    <SubscribeNowButton
      anchorProps={{ target: "_blank" }}
      pathname={config.rosetta.subscribeHost}
      onClick={() => {
        sendGATracking({
          action: "Subscription Entry Point/Homepage inline/Click",
          category: "Subscription Entry Point",
        });
      }}
    >
      SUPPORT NOW
    </SubscribeNowButton>
  </Container>
);

HomeSubscriptionWidget.displayName = "HomeSubscriptionWidget";
