import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { tracking } from "~/data";
import type { heroThreeArticlesQueueItemsEdge$key } from "~/queries/__generated__/heroThreeArticlesQueueItemsEdge.graphql";

import { Container, Item, StyledContentItemHomeSecondary } from "./styles";

type Props = {
  reference: heroThreeArticlesQueueItemsEdge$key;
};

export const HomeHeroThreeArticles: FunctionComponent<Props> = ({ reference }) => {
  const items = useFragment(
    graphql`
      fragment heroThreeArticlesQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            entityId
            ...homeSecondaryContentItemContent
          }
        }
      }
    `,
    reference,
  );

  if (!items) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container>
        {items.map(({ node }, index) => (
          <Item key={index}>
            <StyledContentItemHomeSecondary
              key={node.entityId}
              reference={node}
              withComment={true}
              withLiveTag={true}
              withSection={true}
              withSummary={true}
              onClick={entityId => {
                sendGATracking({
                  action: "Click/top_story_1",
                  category: "Homepage Depth",
                  label: entityId,
                });
              }}
            />
          </Item>
        ))}
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeHeroThreeArticles.displayName = "HomeHeroThreeArticles";
