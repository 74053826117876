import orderBy from "lodash/orderBy";
import { type FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { formatDistanceToNow, toISOString } from "~/lib/utils";
import type { timelinesInSuperSplashLiveQuery } from "~/queries/__generated__/timelinesInSuperSplashLiveQuery.graphql";

import { Container, DateContainer, SnippetTitle, Timeline } from "./styles";

type Props = {
  className?: string;
  entityUuid: string;
  urlAlias: string;
};

export const HomeTimelines: FunctionComponent<Props> = ({ className, entityUuid, urlAlias }) => {
  const data = useLazyLoadQuery<timelinesInSuperSplashLiveQuery>(
    graphql`
      query timelinesInSuperSplashLiveQuery($entityUuid: String!) {
        liveArticle(filter: { entityUuid: $entityUuid }) {
          liveContents {
            entityId
            title
            publishedDate
          }
        }
      }
    `,
    {
      entityUuid,
    },
    { fetchPolicy: "store-or-network" },
  );

  const handleRenderDisplayDateTime = (displayDateTime: number) => {
    if (displayDateTime === 0) return null;
    return (
      <time dateTime={toISOString(displayDateTime)}>{formatDistanceToNow(displayDateTime)}</time>
    );
  };

  const slicedLiveContents = orderBy(
    data?.liveArticle?.liveContents,
    "publishedDate",
    "desc",
  )?.slice(0, 5);

  return (
    <Container className={className}>
      {slicedLiveContents?.map((liveSnippet, key) => {
        if (liveSnippet) {
          return (
            <Timeline
              key={key}
              pathname={`${urlAlias}#live-${liveSnippet.entityId}`}
              onClick={() => {
                sendGATracking({
                  action: "Click/top_story_breaking",
                  category: "Homepage Depth",
                  label: liveSnippet.entityId ?? "",
                });
              }}
            >
              <DateContainer>
                {handleRenderDisplayDateTime(liveSnippet?.publishedDate ?? 0)}
              </DateContainer>
              <SnippetTitle>{liveSnippet.title}</SnippetTitle>
            </Timeline>
          );
        }
      })}
    </Container>
  );
};

HomeTimelines.displayName = "HomeTimelines";
