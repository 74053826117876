import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homePrimaryContentItemContent$key } from "~/queries/__generated__/homePrimaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  CoverImage,
  Headline,
  LinkHighlightArea,
  Section,
  StyledEntityLink,
  Summary,
} from "./styles";

export type Props = {
  reference: homePrimaryContentItemContent$key;
  withComment?: boolean;
  withLiveTag?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    { className, onClick, reference: reference_, withComment = false, withLiveTag = false },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homePrimaryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withActionBar: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <Content>
          <Section>{providers.sections()}</Section>

          <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
            <LinkHighlightArea>
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
                {withLiveTag && providers.liveTag()}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: withComment ?? false,
            })}
          </ActionBar>
        </Content>
        <CoverImage>
          <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
              withCaption: true,
            })}
          </StyledEntityLink>
        </CoverImage>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomePrimary";

export const ContentItemHomePrimary = withHighlightedHeadline(Component);
