import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext } from "react";

type ContextValue = {
  isEnabled?: boolean;
};

type Props = {
  children: ReactNode;
};

const HighlightedHeadlineContext = createContext<ContextValue>({
  isEnabled: false,
});

export const useHighlightedHeadlineContext = () => useContext(HighlightedHeadlineContext);

export const HighlightedHeadlineEffectiveArea: FunctionComponent<Props> = ({ children }) => (
  <HighlightedHeadlineContext.Provider value={{ isEnabled: true }}>
    {children}
  </HighlightedHeadlineContext.Provider>
);
