/**
 * @generated SignedSource<<248f90a201a23096c155c7f02082aee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionTopQuery$data = {
  readonly chinaSection: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly chinaSectionContent: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly chinaSubSections: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly hongKongSection?: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly hongKongSectionContent?: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly hongKongSubSections?: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly lifestyleSection: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly lifestyleSectionContent: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly lifestyleSubSections: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly peopleCultureSection: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly peopleCultureSectionContent: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly peopleCultureSubSections: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly styleSection: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly styleSectionContent: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly styleSubSections: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly twiaSection?: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuSection">;
  };
  readonly twiaSectionContent?: {
    readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue" | "twoSectionTopWidgetQueue">;
  } | null | undefined;
  readonly twiaSubSections?: {
    readonly items: {
      readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionTopQuery";
};
export type sectionTopQuery$key = {
  readonly " $data"?: sectionTopQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionTopQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "subsectionMenuSection"
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "excludeEntityIds",
    "variableName": "excludeSsrContentEntityIds"
  }
],
v2 = [
  {
    "args": (v1/*: any*/),
    "kind": "FragmentSpread",
    "name": "oneSectionTopWidgetQueue"
  },
  {
    "args": (v1/*: any*/),
    "kind": "FragmentSpread",
    "name": "twoSectionTopWidgetQueue"
  }
],
v3 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "subsectionMenuQueue"
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 6
      }
    ],
    "concreteType": "QueueItemConnection",
    "kind": "LinkedField",
    "name": "items",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": "items(first:6)"
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 5
      }
    ],
    "concreteType": "QueueItemConnection",
    "kind": "LinkedField",
    "name": "items",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": "items(first:5)"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeSsrContentEntityIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAsiaEdition"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionTopQuery",
  "selections": [
    {
      "alias": "chinaSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "4"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"4\"})"
    },
    {
      "condition": "isAsiaEdition",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "hongKongSection",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "entityId": "2"
              }
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": "section(filter:{\"entityId\":\"2\"})"
        },
        {
          "alias": "hongKongSectionContent",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_top_2"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_top_2\"})"
        },
        {
          "alias": "hongKongSubSections",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_categories_2"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_categories_2\"})"
        }
      ]
    },
    {
      "alias": "lifestyleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "94"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"94\"})"
    },
    {
      "alias": "peopleCultureSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "318202"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"318202\"})"
    },
    {
      "alias": "styleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "72"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"72\"})"
    },
    {
      "condition": "isAsiaEdition",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "twiaSection",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "entityId": "323045"
              }
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": "section(filter:{\"entityId\":\"323045\"})"
        },
        {
          "alias": "twiaSectionContent",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_top_323045"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_top_323045\"})"
        },
        {
          "alias": "twiaSubSections",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_categories_323045"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_categories_323045\"})"
        }
      ]
    },
    {
      "alias": "chinaSectionContent",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_4"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_4\"})"
    },
    {
      "alias": "lifestyleSectionContent",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_94"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_94\"})"
    },
    {
      "alias": "peopleCultureSectionContent",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_318202"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_318202\"})"
    },
    {
      "alias": "styleSectionContent",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_72"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_72\"})"
    },
    {
      "alias": "chinaSubSections",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_categories_4"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_categories_4\"})"
    },
    {
      "alias": "lifestyleSubSections",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_categories_94"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_categories_94\"})"
    },
    {
      "alias": "peopleCultureSubSections",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_categories_318202"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_categories_318202\"})"
    },
    {
      "alias": "styleSubSections",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_categories_72"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 7
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": "items(first:7)"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_categories_72\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8471424e5da3825bc1240aefdc47547d";

export default node;
