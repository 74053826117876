import { notEmpty } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import { useContentSponsor } from "~/components/content/content-sponsor-tag/hooks";
import type { homeSecondaryContentItemContent$key } from "~/queries/__generated__/homeSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  CoverImage,
  Headline,
  LinkHighlightArea,
  Section,
  SectionContainer,
  SponsorTagContainer,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
  SubHeadline,
  Summary,
} from "./styles";

export type Props = {
  reference: homeSecondaryContentItemContent$key;
  skipHighlight?: boolean;
  withBookmark?: boolean;
  withComment?: boolean;
  withImage?: boolean;
  withLiveTag?: boolean;
  withSection?: boolean;
  withSubHeadline?: boolean;
  withSummary?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onClick,
      reference: reference_,
      skipHighlight = false,
      withBookmark = false,
      withComment = false,
      withImage = false,
      withLiveTag = false,
      withSection = false,
      withSubHeadline = false,
      withSummary = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homeSecondaryContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withSubHeadline: true
              withActionBar: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withSponsorTag: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const contentSponsor = useContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);

    return (
      <Container className={className} ref={reference}>
        {withSection && (
          <SectionContainer>
            <Section>{providers.sections()}</Section>
          </SectionContainer>
        )}

        <StyledHeadlineEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <LinkHighlightArea>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHighlight,
              })}
              {withLiveTag && providers.liveTag()}
            </Headline>

            {withSummary && (
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            )}
            {withSubHeadline && !isSponsorArticle && (
              <SubHeadline>{providers.subHeadline()}</SubHeadline>
            )}
          </LinkHighlightArea>
        </StyledHeadlineEntityLink>

        {isSponsorArticle ? (
          <SponsorTagContainer>{providers.sponsorTag?.({ variant: "home" })}</SponsorTagContainer>
        ) : (
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: withBookmark ?? false,
              isCommentsVisible: withComment ?? false,
            })}
          </ActionBar>
        )}
        {withImage && (
          <StyledCoverEntityLink
            $isSponsorArticle={isSponsorArticle}
            reference={content}
            onClick={() => onClick?.(content.entityId)}
          >
            <CoverImage>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size1200x800",
                  mobileUp: "size540x360",
                  tabletUp: "size1200x800",
                },
              })}
            </CoverImage>
          </StyledCoverEntityLink>
        )}
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeSecondary";

export const ContentItemHomeSecondary = withHighlightedHeadline(Component);
