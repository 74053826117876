import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import type { homeFocusContentItemContent$key } from "~/queries/__generated__/homeFocusContentItemContent.graphql";

import { Container, CoverImage, Headline, ImageContainer, StyledEntityLink } from "./styles";

export type Props = {
  reference: homeFocusContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeFocusContentItemContent on Content {
          ...entityLink
          entityId
          ... on Video {
            duration
          }
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withCoverImage: true
              withCoverImageSize540x360: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
        <Container className={className} ref={reference}>
          <ImageContainer>
            <CoverImage>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size540x360",
                  mobileUp: "size540x360",
                  tabletUp: "size540x360",
                },
              })}
            </CoverImage>
            {content?.duration &&
              providers.video?.durationOverlay({
                variant: "homepage",
              })}
          </ImageContainer>
          <Headline>
            {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
          </Headline>
        </Container>
      </StyledEntityLink>
    );
  },
);

Component.displayName = "ContentItemHomeFocus";

export const ContentItemHomeFocus = withHighlightedHeadline(Component);
