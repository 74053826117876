/* eslint-disable relay/unused-fields */
import { type FunctionComponent, type ReactNode, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { useFetchAiArticles } from "~/components/home/ai-article-list/hooks";
import { AiEngineDataType } from "~/lib/ai-engine/enums";
import { Edition, useEditionValue } from "~/lib/edition";
import { useGetEntityIdsByQueueItems } from "~/pages/home/hooks";
import type {
  clientQueryLoaderHomeQuery,
  clientQueryLoaderHomeQuery$data,
} from "~/queries/__generated__/clientQueryLoaderHomeQuery.graphql";

type Payload = {
  data: clientQueryLoaderHomeQuery$data;
  entityIds: {
    aiWidget: Record<string, string[]>;
    existing: string[];
  };
};

export type Props = {
  children?: (payload: Payload) => ReactNode;
  ssrContentEntityIds: string[];
};

export const HomeClientQueryLoader: FunctionComponent<Props> = ({
  children,
  ssrContentEntityIds,
}) => {
  // No need to SSR for this one so it's ok to use cookie value here
  const edition = useEditionValue();

  const data = useLazyLoadQuery<clientQueryLoaderHomeQuery>(
    graphql`
      query clientQueryLoaderHomeQuery(
        $multimediaQueueName: String!
        $advertisersQueueName: String!
        $trendingTopicsQueueName: String!
        $isAsiaEdition: Boolean!
        $excludeSsrContentEntityIds: [String]!
      ) {
        multimedia: queue(filter: { name: $multimediaQueueName }) {
          items(first: 4) {
            ...multimediaQueue
          }
        }
        advertisers: queue(filter: { name: $advertisersQueueName }) {
          items(first: 20) {
            ...advertisersQueueItemConnection
          }
        }
        ...mostPopularQueueQuery @arguments(trendingTopicsQueueName: $trendingTopicsQueueName)
        ...sectionTopQuery
          @arguments(
            isAsiaEdition: $isAsiaEdition
            excludeSsrContentEntityIds: $excludeSsrContentEntityIds
          )
        china: queue(filter: { name: "section_top_4" }) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        hongKong: queue(filter: { name: "section_top_2" }) @skip(if: $isAsiaEdition) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        lifestyle: queue(filter: { name: "section_top_94" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        peopleCulture: queue(filter: { name: "section_top_318202" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        style: queue(filter: { name: "section_top_72" }) {
          items(first: 5, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        twia: queue(filter: { name: "section_top_323045" }) @include(if: $isAsiaEdition) {
          items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
            edges {
              ...hooksGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
      }
    `,
    {
      advertisersQueueName: "homepage_brand_post_int",
      excludeSsrContentEntityIds: ssrContentEntityIds,
      // one queue for all editions
      isAsiaEdition: edition === Edition.Asia,
      multimediaQueueName: `visual_stories_top_${edition}`,
      trendingTopicsQueueName: `related_topic_homepage_${edition}_`,
    },
    { fetchPolicy: "store-or-network" },
  );

  const sectionEdges = useMemo(
    () => [
      ...(data?.hongKong?.items?.edges ?? []),
      ...(data?.china?.items?.edges ?? []),
      ...(data?.lifestyle?.items?.edges ?? []),
      ...(data?.peopleCulture?.items?.edges ?? []),
      ...(data?.style?.items?.edges ?? []),
      ...(data?.twia?.items?.edges ?? []),
    ],
    [
      data?.china?.items?.edges,
      data?.hongKong?.items?.edges,
      data?.lifestyle?.items?.edges,
      data?.peopleCulture?.items?.edges,
      data?.style?.items?.edges,
      data?.twia?.items?.edges,
    ],
  );
  const existingEntityIds = useGetEntityIdsByQueueItems(sectionEdges, ssrContentEntityIds);

  const { entityIds: recommendedForYouEntityIds } = useFetchAiArticles(
    AiEngineDataType.HomeRecommendedForYou,
    existingEntityIds,
  );

  const { entityIds: youMayHaveMissedEntityIds } = useFetchAiArticles(
    AiEngineDataType.HomeYouMayHaveMissed,
    [...existingEntityIds, ...recommendedForYouEntityIds],
  );

  return children?.({
    data,
    entityIds: {
      aiWidget: {
        [AiEngineDataType.HomeRecommendedForYou]: recommendedForYouEntityIds,
        [AiEngineDataType.HomeYouMayHaveMissed]: youMayHaveMissedEntityIds,
      },
      existing: existingEntityIds,
    },
  });
};

HomeClientQueryLoader.displayName = "HomeClientQueryLoader";
