/**
 * @generated SignedSource<<a4d4796c442ee5036a0546be3835dc32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksGetEntityIdsByQueueItemQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly entityId?: string;
  };
  readonly " $fragmentType": "hooksGetEntityIdsByQueueItemQueueItemsEdge";
}>;
export type hooksGetEntityIdsByQueueItemQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: hooksGetEntityIdsByQueueItemQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksGetEntityIdsByQueueItemQueueItemsEdge">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "hooksGetEntityIdsByQueueItemQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "97eb44cb690149b2cf4350eeb2851e46";

export default node;
