import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

export type ContainerProps = {
  $variant?: "posties" | "magazines-style" | "home";
};
export const Container = styled("div", {
  ...transientOptions,
})<ContainerProps>`
  ${props =>
    props.$variant !== "home" &&
    css`
      background-color: #222222;
    `}
`;
