import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { StyledArticleComment } from "~/components/content/content-action-bar/styles";
import { ContentItemHomeSuperSplashLargePrimary } from "~/components/content/content-item-render/variants/home-super-splash-large-primary";
import { ContentItemHomeSuperSplashLiveSecondary } from "~/components/content/content-item-render/variants/home-super-splash-live-secondary";

export const Container = styled.div``;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;

  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid #000000;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 32px;
    padding-block-end: 32px;
  }
`;

type LabelContainerProps = {
  $labelType: LabelType;
};

export const LabelContainer = styled.div<LabelContainerProps>`
  margin-block-end: 20px;
  padding-inline: 4px;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 32px;
    padding-inline: ${props => (props.$labelType === "large" ? "0" : "32px")};
  }
`;

export type LabelType = "large" | "small";

type LabelProps = {
  $labelType: LabelType;
};

export const Label = styled.div<LabelProps>`
  color: #000000;
  font-weight: 300;
  font-size: ${props => (props.$labelType === "large" ? "48px" : "28px")};
  font-family: ${fontMerriweather};
  line-height: ${props => (props.$labelType === "large" ? "60.34px;" : "35.2px")};
  text-align: center;
  text-transform: ${props => (props.$labelType === "large" ? "uppercase;" : "initial")};
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: ${props => (props.$labelType === "large" ? "72px;" : "40px")};
    line-height: ${props => (props.$labelType === "large" ? "90.5px;" : "50.28px")};
  }
`;

type DescriptionProps = {
  $labelType: LabelType;
};
export const Description = styled.div<DescriptionProps>`
  margin-block-start: 12px;

  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 19.6px;
  text-align: center;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 8px;
    padding-inline: 32px;

    font-size: 16px;
    line-height: 22.4px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: ${props => (props.$labelType === "large" ? "968px;" : "904px")};
    margin-inline: auto;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-inline: 0;
  }
`;

export const StyledContentItemHomeSuperSplashLargePrimary = styled(
  ContentItemHomeSuperSplashLargePrimary,
)``;

export const StyledContentItemHomeSuperSplashLiveSecondary = styled(
  ContentItemHomeSuperSplashLiveSecondary,
)`
  ${props => props.theme.breakpoints.up("tablet")} {
    flex: 1;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex: initial;
  }
`;

const borderStyle = css`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TwoArticlesInMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${borderStyle};
  ${StyledContentItemHomeSuperSplashLiveSecondary} + ${StyledContentItemHomeSuperSplashLiveSecondary} {
    margin-inline-start: 0;
    ${borderStyle};
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    ${StyledContentItemHomeSuperSplashLiveSecondary} + ${StyledContentItemHomeSuperSplashLiveSecondary} {
      margin-block-start: 0;
      margin-inline-start: 32px;
      padding-block-start: 0;

      border: none;
    }
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: none;
  }
`;

export const TwoArticlesInDesktopContainer = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
    ${StyledContentItemHomeSuperSplashLiveSecondary} + ${StyledContentItemHomeSuperSplashLiveSecondary} {
      ${borderStyle};
    }
  }
`;

export const RestArticlesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${StyledContentItemHomeSuperSplashLiveSecondary} {
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    margin-block-start: 20px;
    padding-block-start: 20px;

    ${props => props.theme.breakpoints.up("tablet")} {
      border-block-start: none;

      margin-block-start: 0;
      padding-block-start: 0;
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    grid-template-columns: repeat(4, 1fr);
    gap: 0 32px;
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    margin-block-start: 22px;
    padding-block-start: 22px;
  }

  ${StyledArticleComment} {
    ${props => props.theme.breakpoints.up("tablet")} {
      display: none;
    }
  }
`;
