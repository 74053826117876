import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { set } from "date-fns";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

import {
  MyDailyFiveArticleIdsStorageKey,
  MyDailyFiveLastUpdateTimeStorageKey,
  MyDailyFiveRecommendIdStorageKey,
} from "~/components/home/my-news-daily-five/consts";
import { accountAtom } from "~/lib/account";
import { fetchAiEngineData } from "~/lib/ai-engine/apis";
import { AiEngineDataType } from "~/lib/ai-engine/enums";
import { addDays, isAfterOrEqual } from "~/lib/utils";

type UseMyNewDailyFiveArticleIdsParameters = {
  authorIds?: string[];
  sectionIds?: string[];
  topicIds?: string[];
};

export const useMyNewDailyFiveArticleIds = ({
  authorIds,
  sectionIds,
  topicIds,
}: UseMyNewDailyFiveArticleIdsParameters) => {
  const { set: setExpiredDateTime, value: expiredDateTime } = useFallbackStorageValue(
    MyDailyFiveLastUpdateTimeStorageKey,
    {
      defaultValue: "0",
    },
  );

  const { set: setLocalStorageRecommendedArticleIds, value: localStorageRecommendedArticleIds } =
    useFallbackStorageValue<string[]>(MyDailyFiveArticleIdsStorageKey, {
      defaultValue: [],
    });

  const { set: setMyDailyFiveRecommendIdStorageKey } = useFallbackStorageValue(
    MyDailyFiveRecommendIdStorageKey,
    {
      defaultValue: "",
    },
  );

  const { user } = useAtomValue(accountAtom);

  const [{ result }, { execute }] = useAsync(() =>
    fetchAiEngineData<AiEngineDataType.MyNewsFresh>({
      authorIds,
      sectionEntityIds: sectionIds,
      topicIds,
      type: AiEngineDataType.MyNewsFresh,
      userUuid: user?.uuid,
    }),
  );

  const isExpired = expiredDateTime ? isAfterOrEqual(Date.now(), expiredDateTime) : true;

  useMountEffect(() => {
    if (!isExpired) {
      return;
    }

    void execute();
  });

  useEffect(() => {
    const data = result?.data;
    if (!data) {
      return;
    }

    setMyDailyFiveRecommendIdStorageKey(data.rec_id ?? "");

    const recommendedArticleIds = data.results.map(result => result.id) ?? [];
    setLocalStorageRecommendedArticleIds(recommendedArticleIds);

    const expiredDateTime = addDays(set(new Date(), { hours: 4, minutes: 59 }), 1).toISOString();
    setExpiredDateTime(expiredDateTime);
  }, [
    result?.data,
    setExpiredDateTime,
    setLocalStorageRecommendedArticleIds,
    setMyDailyFiveRecommendIdStorageKey,
  ]);

  return {
    recommendedArticleIds: localStorageRecommendedArticleIds,
  };
};
