import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { ContentItemHomePrimary } from "~/components/content/content-item-render/variants/home-primary";
import {
  ActionBar as HomePrimaryActionBar,
  Container as HomePrimaryContainer,
  CoverImage as HomePrimaryCoverImage,
  Headline as HomePrimaryHeadline,
} from "~/components/content/content-item-render/variants/home-primary/styles";
import { ContentItemHomeSecondary } from "~/components/content/content-item-render/variants/home-secondary";
import {
  Headline,
  Section,
  Summary,
} from "~/components/content/content-item-render/variants/home-secondary/styles";
import { HomeSponsorTagWrapper } from "~/components/sponsor-tag/styles";

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;
export const StyledContentItemHomePrimary = styled(ContentItemHomePrimary)``;

export const Container = styled.div`
  display: grid;
  grid:
    "title" min-content
    "primary" min-content
    "secondary" min-content
    "tertiary" min-content
    / minmax(0, 1fr);

  border-block-start: 1px rgb(0, 0, 0) solid;

  padding-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "title title" min-content
      "primary tertiary" min-content
      "secondary tertiary" min-content
      / minmax(0, 1fr) calc((100% - 64px) / 3);

    column-gap: 32px;
  }
`;

export const Title = styled.div`
  grid-area: title;

  margin-block-end: 20px;

  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const Primary = styled.div`
  grid-area: primary;

  ${HomePrimaryHeadline} {
    margin-block-end: 8px;
  }

  ${props => props.theme.breakpoints.only("mobile")} {
    ${HomePrimaryContainer} {
      display: flex;
      flex-flow: column-reverse nowrap;
    }
    ${HomePrimaryActionBar} {
      margin: 0;
    }
    ${HomePrimaryCoverImage} {
      margin-block-end: 12px;
    }
  }

  ${StyledContentItemHomePrimary} {
    margin-block-end: 20px;
    padding-block-end: 20px;
    border-block-end: 1px rgba(0, 0, 0, 0.1) solid;
  }
`;

export const Secondary = styled.div`
  grid-area: secondary;

  ${Summary} {
    display: none;

    ${props => props.theme.breakpoints.up("tablet")} {
      display: block;
    }
  }

  ${props => props.theme.breakpoints.only("mobile")} {
    margin-block-end: 20px;
    padding-block-end: 20px;
    border-block-end: 1px rgba(0, 0, 0, 0.1) solid;
  }
`;

export const Tertiary = styled.div`
  grid-area: tertiary;

  & > *:not(:last-child) {
    margin-block-end: 20px;
    padding-block-end: 20px;
    border-block-end: 1px rgba(0, 0, 0, 0.1) solid;
  }

  ${StyledContentItemHomeSecondary} {
    ${Section} {
      color: #4585ff;
      font-weight: 700;
      font-size: 13px;
      font-family: ${fontRobotoCondensed};
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
    }

    ${Headline} {
      color: #000000;
      font-weight: 700;
      font-size: 18px;
      font-family: ${fontMerriweather};
      font-style: normal;
      line-height: 140%;

      ${theme.breakpoints.up("tablet")} {
        font-size: 15px;
      }
    }

    ${HomeSponsorTagWrapper} {
      display: block;

      max-inline-size: 240px;
    }
  }
`;
