import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { ContentItemHomeMultimedia } from "~/components/content/content-item-render/variants/home-multimedia";
import { ContentItemHomeMultimediaPodcast } from "~/components/content/content-item-render/variants/home-multimedia-podcast";

export const StyledContentItemHomeMultimedia = styled(ContentItemHomeMultimedia)``;
export const StyledContentItemHomeMultimediaPodcast = styled(ContentItemHomeMultimediaPodcast)``;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid rgba(0, 0, 0, 0.2);
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 300px;
  }

  ${StyledContentItemHomeMultimedia}:not(:first-child), ${StyledContentItemHomeMultimediaPodcast}:not(:first-child) {
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    margin-block-start: 20px;
    padding-block-start: 20px;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: 0;
      border-block-start: 0;

      padding-block-start: 0;
    }
    ${props => props.theme.breakpoints.up("homeDesktop")} {
      margin-block-start: 20px;
      padding-block-start: 20px;
      border-block-start: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Title = styled.div`
  margin-block-end: 20px;

  color: rgb(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 20px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: column;
    gap: 0;
  }
`;
