import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import { EntityLink } from "~/components/entity-link";
import type { homeStoryPackageTailContentItemContent$key } from "~/queries/__generated__/homeStoryPackageTailContentItemContent.graphql";

import {
  ActionBarContainer,
  Container,
  Headline,
  HeadlineContainer,
  LiveTagContainer,
} from "./styles";

type Props = {
  reference: homeStoryPackageTailContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeStoryPackageTailContentItemContent on Content {
          entityId
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withHeadline: true, withLiveTag: true, withActionBar: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <EntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <HeadlineContainer>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHighlight: true,
              })}
            </Headline>
            <LiveTagContainer>{providers.liveTag()}</LiveTagContainer>
          </HeadlineContainer>
        </EntityLink>
        <ActionBarContainer>
          {providers.actionBar({
            commentVariant: "reverse",
            isBookmarkVisible: false,
            isCommentsVisible: true,
          })}
        </ActionBarContainer>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeStoryPackageTail";

export const ContentItemHomeStoryPackageTail = withHighlightedHeadline(Component);
