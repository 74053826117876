/**
 * @generated SignedSource<<62aa72d006a2db854e00e04af67aba95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type oneSectionTopWidgetQueue$data = {
  readonly variantOneItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "homeSecondaryContentItemContent" | "homeSectionLeadPrimaryContentItemContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "oneSectionTopWidgetQueue";
};
export type oneSectionTopWidgetQueue$key = {
  readonly " $data"?: oneSectionTopWidgetQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeEntityIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "oneSectionTopWidgetQueue",
  "selections": [
    {
      "alias": "variantOneItems",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityIds",
              "variableName": "excludeEntityIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "exclude"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 7
        }
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "entityLink"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeSecondaryContentItemContent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeSectionLeadPrimaryContentItemContent"
                    }
                  ],
                  "type": "Content",
                  "abstractKey": "__isContent"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Queue",
  "abstractKey": null
};

(node as any).hash = "5bfa68c11711449654dc9de71dd04e26";

export default node;
