/**
 * @generated SignedSource<<3422b862a04ae8fe8d1c951fc847e9d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topStoriesItemContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"homePrimaryContentItemContent" | "homeSecondaryContentItemContent">;
  readonly " $fragmentType": "topStoriesItemContent";
};
export type topStoriesItemContent$key = {
  readonly " $data"?: topStoriesItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topStoriesItemContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homePrimaryContentItemContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeSecondaryContentItemContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "2e71c05e6a32e34a32b1b48a51b18974";

export default node;
