import { FollowType } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { tracking } from "~/data";
import type { trendingTopicTopic$key } from "~/queries/__generated__/trendingTopicTopic.graphql";

import { Container, StyledEntityLink, StyledHomeFollowButton } from "./styles";
type Props = {
  className?: string;
  reference: trendingTopicTopic$key;
};

export const HomeTrendingTopic: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment trendingTopicTopic on Topic {
        ...entityLink
        ...followButtonBase
        name
      }
    `,
    reference_,
  );

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TrendingTopics,
      }}
    >
      <Container className={className}>
        <StyledEntityLink
          reference={data}
          onClick={() => {
            sendGATracking({
              action: "Click/trending_topics",
              category: "Homepage Depth",
              label: data.name,
            });
          }}
        >
          {data.name}
        </StyledEntityLink>
        <StyledHomeFollowButton
          buttonVariant="square"
          reference={data}
          source="Trending Topics"
          type={FollowType.Topic}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeTrendingTopic.displayName = "HomeTrendingTopic";
