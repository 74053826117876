import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { Figure } from "~/components/content/content-cover-image/styles";
import { EntityLink } from "~/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  block-size: 100%;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;
  flex: 1;
  flex-direction: column;

  color: #000000;
`;

export const CoverImage = styled.div`
  position: relative;
  ${Figure} {
    aspect-ratio: 3/2;
  }
`;

export const Content = styled.div`
  margin-block-start: 12px;
`;

export const Headline = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;
