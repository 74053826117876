/**
 * @generated SignedSource<<7ad32a42ac6b0aa248ab4031aa948c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topStoriesQueueItemsEdge$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
  readonly " $fragmentType": "topStoriesQueueItemsEdge";
}>;
export type topStoriesQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: topStoriesQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesQueueItemsEdge">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "topStoriesQueueItemsEdge",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "topStoriesBlockQueueItemsEdge"
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "b702ec9bd781f225b4aa6b6b74ae6a59";

export default node;
