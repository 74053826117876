/**
 * @generated SignedSource<<d563d0386cd6221b2eaea4ba593ed7e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type multimediaQueue$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly __typename: string;
      readonly entityId?: string;
      readonly types?: ReadonlyArray<{
        readonly value: ReadonlyArray<{
          readonly name: string;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"homeMultimediaContentItemContent" | "homeMultimediaPodcastContentItemContent">;
    };
  }>;
  readonly " $fragmentType": "multimediaQueue";
};
export type multimediaQueue$key = {
  readonly " $data"?: multimediaQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"multimediaQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multimediaQueue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QueueItemsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entityId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "homeMultimediaContentItemContent"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "homeMultimediaPodcastContentItemContent"
                }
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemConnection",
  "abstractKey": null
};

(node as any).hash = "848fcaff00f14fe50e1acfdeb198c569";

export default node;
