/* eslint-disable relay/unused-fields */
import { theme } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "~/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "~/components/advertisement/ad-slots/types";
import type { topStoriesBlockQueueItemsEdge$key } from "~/queries/__generated__/topStoriesBlockQueueItemsEdge.graphql";

import {
  AdsSlotContainer,
  Container,
  LeftContainer,
  MLrecAdContainer,
  RightContainer,
  StyledTopStoriesItem,
} from "./styles";
import type { Props as TopStoriesItemProps } from "./top-stories-item";

type Props = {
  adZone?: string;
  className?: string;
  isShowAds?: boolean;
  isShowMLrecAd?: boolean;
  reference: topStoriesBlockQueueItemsEdge$key;
} & Pick<TopStoriesItemProps, "onClick">;

export const HomeTopStoriesBlock = forwardRef<HTMLDivElement, Props>(
  (
    { adZone, className, isShowAds = false, isShowMLrecAd = false, onClick, reference: reference_ },
    reference,
  ) => {
    const items = useFragment(
      graphql`
        fragment topStoriesBlockQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
          node {
            ... on Content {
              ...topStoriesItemContent
            }
          }
        }
      `,
      reference_,
    );

    if (!items) return null;

    const [first, second, ...rest] = items.map(({ node }) => node);
    return (
      <Container className={className} ref={reference}>
        <LeftContainer $isShowMLrecAd={isShowMLrecAd}>
          <StyledTopStoriesItem reference={first} variant="primary" onClick={onClick} />
          {isShowMLrecAd && (
            <MLrecAdContainer>
              <AdSlot
                adUnit="m_lrec1"
                breakpoint={theme.breakpoints.only("mobile")}
                sizes={[[300, 250], "fluid"]}
                targeting={{
                  paid: TargetingPaidType.Free,
                }}
                zone={adZone}
              />
            </MLrecAdContainer>
          )}
          <StyledTopStoriesItem reference={second} variant="secondary" onClick={onClick} />
        </LeftContainer>
        <RightContainer>
          {rest.map((item, itemIndex) => (
            <StyledTopStoriesItem
              isShowImage={!isShowAds && itemIndex === rest.length - 1}
              key={itemIndex}
              reference={item}
              variant="secondary"
              onClick={onClick}
            />
          ))}
          {isShowAds && (
            <AdsSlotContainer>
              <AdSlot
                adUnit="d_native2c"
                sizes={[[1, 1], "fluid"]}
                targeting={{
                  paid: TargetingPaidType.Free,
                }}
                zone={adZone}
              />
            </AdsSlotContainer>
          )}
        </RightContainer>
      </Container>
    );
  },
);

HomeTopStoriesBlock.displayName = "HomeTopStoriesBlock";
