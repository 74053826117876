import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { HomeSuperSplashLarge } from "~/components/home/super-splash/variants/super-splash-large";
import { HomeSuperSplashLive } from "~/components/home/super-splash/variants/super-splash-live";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import { tracking } from "~/data";
import type { superSplashQuery$key } from "~/queries/__generated__/superSplashQuery.graphql";

import { Container } from "./styles";

type Props = {
  className?: string;
  reference: superSplashQuery$key;
};

export const HomeSuperSplash: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment superSplashQuery on Query
      @argumentDefinitions(edition: { type: "LiveWidgetEditionEnum!" }) {
        liveWidget(
          filter: { edition: $edition }
          orderBy: { direction: DESC, field: UPDATED_DATE }
        ) {
          style
          ...superSplashLiveLiveWidget
          ...superSplashLargeLiveWidget
        }
      }
    `,
    reference_,
  );

  const style = data?.liveWidget?.style;

  const liveWidget = data?.liveWidget;

  const impressionEvent = useCallback<() => Event>(
    () => ({
      action: "Impression/top_story_breaking",
      category: "Homepage Depth",
    }),
    [],
  );
  const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
    Event,
    null
  >({
    gaTrackingHandler: sendGATracking,
    getEvent: impressionEvent,
    options: { isSendGATracking: true, shouldSendOnce: true },
  });

  const superSplashWidget = useMemo(() => {
    if (!liveWidget) return null;
    switch (style) {
      case "live":
        return <HomeSuperSplashLive reference={liveWidget} />;
      case "large_1":
      case "large_2":
        const labelType = style === "large_1" ? "large" : "small";
        return <HomeSuperSplashLarge labelType={labelType} reference={liveWidget} />;
    }
    return null;
  }, [liveWidget, style]);

  if (!superSplashWidget) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TopStory,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        {superSplashWidget}
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeSuperSplash.displayName = "HomeSuperSplash";
