/**
 * @generated SignedSource<<f1130a2ebb3ff0e95f0f38741cabe23a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeHeroArticleContentItemContent$data = {
  readonly entityId: string;
  readonly moreOnThisArticles: ReadonlyArray<{
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"contentHeadlineContent" | "entityLink">;
  } | null | undefined> | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentTopicLinkContent" | "entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeHeroArticleContentItemContent";
};
export type homeHeroArticleContentItemContent$key = {
  readonly " $data"?: homeHeroArticleContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeHeroArticleContentItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "entityLink"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeHeroArticleContentItemContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "moreOnThisArticles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentHeadlineContent"
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "followButtonBase"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentTopicLinkContent"
    },
    (v1/*: any*/),
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withLiveTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSummary",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withTopicLink",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "d345a23b2d0bbbff40942bfbd9919f62";

export default node;
