import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";
import classNames from "classnames";
import differenceWith from "lodash/differenceWith";
import { type ComponentType, forwardRef, useCallback, useEffect, useState } from "react";

import {
  Headline,
  Container as HeadlineContainer,
  SponsorHeadline,
} from "~/components/content/content-headline/styles";

import { useHighlightedHeadlineContext } from "./contexts";

export function withHighlightedHeadline<Props extends object>(Component: ComponentType<Props>) {
  type StyledProps = {
    $hovered: boolean;
  };
  const StyleComponent = styled(Component, { ...transientOptions })<StyledProps>`
    ${HeadlineContainer} {
      ${Headline}:hover, ${SponsorHeadline}:hover {
        color: #2c4692;
      }
    }
    ${Headline}:not([data-skip-highlight="true"]), ${SponsorHeadline}:not([data-skip-highlight="true"]) {
      color: ${props => (props.$hovered ? "#2c4692" : "inherit")};
    }
  `;

  const WrappedComponent = forwardRef<HTMLElement, Props>((props, elementReference) => {
    const { isEnabled } = useHighlightedHeadlineContext();
    const [element, setElement] = useState<HTMLElement>();
    const [hovered, setHovered] = useState(false);

    const reference = useCallback((element_: HTMLElement | null) => {
      if (!element_) return;
      setElement(element_);
    }, []);

    useEffect(() => {
      if (!element || !elementReference) return;

      if (typeof elementReference === "function") {
        elementReference(element);
      } else {
        elementReference.current = element;
      }
    }, [element, elementReference]);

    useEffect(() => {
      if (!isEnabled || !element) return;

      const handleMouseEnter = () => {
        setHovered(true);
      };
      const handleMouseLeave = () => {
        setHovered(false);
      };

      // Exclude those have another set of highlight elements
      const excludeElements = element.querySelectorAll(
        ["ContentCoverImage", "LinkHighlightArea", "HomepageVideoDurationOverlay"]
          .map(name => `:scope .highlight-target [data-qa*="${name}"]`)
          .join(","),
      );

      const triggers = differenceWith(
        element.querySelectorAll(
          ["ContentCoverImage", "LinkHighlightArea", "HomepageVideoDurationOverlay"]
            .map(name => `[data-qa*="${name}"]`)
            .join(","),
        ),
        excludeElements,
      );

      triggers.forEach(trigger => {
        trigger.addEventListener("mouseover", handleMouseEnter);
        trigger.addEventListener("mouseout", handleMouseLeave);
      });

      return () => {
        triggers.forEach(trigger => {
          trigger.removeEventListener("mouseover", handleMouseEnter);
          trigger.removeEventListener("mouseout", handleMouseLeave);
        });
      };
    }, [isEnabled, element]);

    const { className } = props as { className?: string };

    if (!isEnabled) return <Component {...props} ref={reference} />;

    return (
      <StyleComponent
        {...props}
        $hovered={hovered}
        className={classNames(className, "highlight-target")}
        ref={reference}
      />
    );
  });

  WrappedComponent.displayName = "WrappedComponent";
  return WrappedComponent;
}
