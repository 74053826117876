import { useAtomValue } from "jotai";
import { useMemo } from "react";
import usePromise from "react-promise-suspense";

import { accountAtom } from "~/lib/account/atoms";
import { fetchAiEngineData } from "~/lib/ai-engine/apis";
import { AiEngineDataType } from "~/lib/ai-engine/enums";
import type {
  AiRecommendedForYouResponse,
  AiYouMayHaveMissedResponse,
} from "~/lib/ai-engine/types";

import type { Props } from ".";

export const useFetchAiArticles = (targetVariant: Props["variant"], excludeEntityIds: string[]) => {
  const { user } = useAtomValue(accountAtom);

  const result = usePromise(
    async (variant: Props["variant"], excludeIds?: string[]) =>
      fetchAiEngineData<typeof variant>({
        excludeIds,
        type: variant,
        userUuid: user?.uuid,
      }),
    [targetVariant, excludeEntityIds],
  );

  const entityIds = useMemo(() => {
    if (!result?.data) return [];

    switch (targetVariant) {
      case AiEngineDataType.HomeRecommendedForYou: {
        const { results } = result.data as AiRecommendedForYouResponse;
        return [...results].sort((a, b) => a.rank - b.rank).flatMap(data => data.id);
      }
      case AiEngineDataType.HomeYouMayHaveMissed: {
        const { results } = result.data as AiYouMayHaveMissedResponse;
        return [...results].flatMap(data => data.id);
      }
    }
  }, [targetVariant, result?.data]);

  return {
    aiEngineRecommendId: result.data.rec_id,
    entityIds,
  };
};
