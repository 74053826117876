import { FollowType, notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "~/components/content/content-item-render/hoc";
import { useContentItemProviders } from "~/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "~/components/content/content-item-render/types";
import { useContentSponsor } from "~/components/content/content-sponsor-tag/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { homeSectionLeadSecondaryContentItemContent$key } from "~/queries/__generated__/homeSectionLeadSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Headline,
  LeadingImage,
  LinkHighlightArea,
  Primary,
  StyledContentCoverImage,
  StyledEntityLink,
  StyledHomeFollowButton,
  Summary,
  Topic,
  TopicContainer,
} from "./styles";

export type Props = {
  reference: homeSectionLeadSecondaryContentItemContent$key;
  withBookmark?: boolean;
  withComment?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      customQueryParameters,
      onClick,
      reference: reference_,
      withBookmark = false,
      withComment = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homeSectionLeadSecondaryContentItemContent on Content {
          entityId
          topics {
            ...followButtonBase
          }
          ...entityLink
          ...contentCoverImageContent @arguments(withSize1200x800: true)
          ...hooksUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withTopicLink: true
              withHeadline: true
              withSummary: true
              withActionBar: true
              withSponsorTag: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, { type: "main" });

    const contentSponsor = useContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);
    const topic = first(content?.topics ?? []);

    return (
      <Container className={className} ref={reference}>
        <LeadingImage>
          <StyledEntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
            <StyledContentCoverImage
              reference={content}
              responsiveVariants={{
                desktopUp: "size1200x800",
                mobileUp: "size1200x800",
                tabletUp: "size1200x800",
              }}
            />
          </StyledEntityLink>
        </LeadingImage>
        <Primary>
          <TopicContainer>
            <Topic>{providers.topicLink?.()}</Topic>
            {topic && (
              <StyledHomeFollowButton
                buttonVariant="square"
                reference={topic}
                type={FollowType.Topic}
                onClick={isActionFollow => {
                  if (!isActionFollow) return;
                  sendGATracking({
                    action: `Notification Center/Follow/HP Section Block/Click` as const,
                    category: "Notification Center",
                  });
                }}
              />
            )}
          </TopicContainer>

          <StyledEntityLink
            query={customQueryParameters}
            reference={content}
            onClick={() => onClick?.(content.entityId)}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>

          {isSponsorArticle ? (
            providers.sponsorTag?.({ variant: "home" })
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                isBookmarkVisible: withBookmark ?? false,
                isCommentsVisible: withComment ?? false,
              })}
            </ActionBar>
          )}
        </Primary>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeSectionLeadSecondary";

export const ContentItemHomeSectionLeadSecondary = withHighlightedHeadline(Component);
