import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { topStoriesItemContent$key } from "~/queries/__generated__/topStoriesItemContent.graphql";

import { Container, StyledContentItemHomePrimary, StyledContentItemHomeSecondary } from "./styles";

export type Props = {
  className?: string;
  isShowImage?: boolean;
  onClick: (entityId?: string) => void;
  reference: topStoriesItemContent$key;
  variant: "primary" | "secondary";
};

export const TopStoriesItem: FunctionComponent<Props> = ({
  className,
  isShowImage = false,
  onClick,
  reference,
  variant,
}) => {
  const content = useFragment(
    graphql`
      fragment topStoriesItemContent on Content {
        ...homePrimaryContentItemContent
        ...homeSecondaryContentItemContent
      }
    `,
    reference,
  );

  const renderItem = () => {
    switch (variant) {
      case "primary":
        return (
          <StyledContentItemHomePrimary
            reference={content}
            withComment={true}
            withLiveTag={true}
            onClick={entityId => {
              onClick(entityId);
            }}
          />
        );
      case "secondary":
        return (
          <StyledContentItemHomeSecondary
            reference={content}
            withComment={true}
            withImage={isShowImage}
            withLiveTag={true}
            withSection={true}
            withSummary={true}
            onClick={entityId => {
              onClick(entityId);
            }}
          />
        );
    }
  };

  return <Container className={className}>{renderItem()}</Container>;
};

TopStoriesItem.displayName = "TopStoriesItem";
