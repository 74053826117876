import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { Figure } from "~/components/content/content-cover-image/styles";
import { EntityLink } from "~/components/entity-link";

export const Container = styled.div`
  display: grid;
  grid:
    "cover-image" min-content
    "headline" min-content
    / 100%;
  gap: 12px;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    grid: "headline cover-image" min-content / minmax(260px, 338px) minmax(400px, 590px);
    gap: 32px;
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Headline = styled.div`
  grid-area: headline;

  color: #000000;
  font-weight: 300;
  font-size: 20px;
  font-family: ${fontMerriweather};
  line-height: 140%;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 28px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 20px;
  }
`;

export const CoverImage = styled.div`
  grid-area: cover-image;
  ${Figure} {
    aspect-ratio: 16/9;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;
