import styled from "@emotion/styled";

import { ContentItemHomePrimary } from "~/components/content/content-item-render/variants/home-primary";
import { ContentItemHomeSecondary } from "~/components/content/content-item-render/variants/home-secondary";
import { Summary } from "~/components/content/content-item-render/variants/home-secondary/styles";
import { HomeSponsorTagWrapper } from "~/components/sponsor-tag/styles";

export const StyledContentItemHomePrimary = styled(ContentItemHomePrimary)``;
export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;

export const Container = styled.div`
  ${HomeSponsorTagWrapper} {
    display: block;
  }

  ${Summary} {
    ${props => props.theme.breakpoints.only("mobile")} {
      display: none;
    }
  }
`;
