import { FollowType } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { EntityFollowMenu } from "~/components/home/entity-follow-menu";
import { section as sectionData } from "~/data/section";
import type { subsectionMenuQueue$key } from "~/queries/__generated__/subsectionMenuQueue.graphql";
import type { subsectionMenuSection$key } from "~/queries/__generated__/subsectionMenuSection.graphql";

import {
  Container,
  SectionBadge,
  SectionName,
  StyledHomeFollowButton,
  StyledStyleIcon,
  Subsection,
} from "./styles";

type Props = {
  reference: subsectionMenuQueue$key;
  sectionReference: subsectionMenuSection$key;
};

export const SubsectionMenu: FunctionComponent<Props> = ({ reference, sectionReference }) => {
  const data = useFragment(
    graphql`
      fragment subsectionMenuQueue on QueueItemConnection {
        edges {
          ...entityFollowMenuQueueItemsEdge
        }
      }
    `,
    reference,
  );

  const section = useFragment(
    graphql`
      fragment subsectionMenuSection on Section {
        ...followButtonBase
        entityId
        name
        urlAlias
      }
    `,
    sectionReference,
  );

  const isStyleSection = section.entityId === sectionData.style.entityId;
  const subsections = data.edges ?? [];

  return (
    <Container>
      <SectionBadge>
        <SectionName pathname={section.urlAlias}>
          {isStyleSection ? <StyledStyleIcon /> : section.name}
        </SectionName>
        <StyledHomeFollowButton
          reference={section}
          source="HP Section Block"
          type={FollowType.Section}
        />
      </SectionBadge>
      <Subsection>
        <EntityFollowMenu reference={subsections} />
      </Subsection>
    </Container>
  );
};

SubsectionMenu.displayName = "SubsectionMenu";
