/**
 * @generated SignedSource<<d6694ab21143fdb47d60b2e1aec266e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeMultimediaContentItemContent$data = {
  readonly entityId: string;
  readonly images?: ReadonlyArray<{
    readonly __typename: "Image";
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "helpersGetLabelContent" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "homeMultimediaContentItemContent";
};
export type homeMultimediaContentItemContent$key = {
  readonly " $data"?: homeMultimediaContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeMultimediaContentItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeMultimediaContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Gallery",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetLabelContent",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleTypeSegment",
              "kind": "LinkedField",
              "name": "types",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleType",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SectionSegment",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Section",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withVideoDurationOverlay",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "fcd3d8e8776c54db023520083c00a5d7";

export default node;
