/**
 * @generated SignedSource<<7d29eb82a00c9b9cc3dc98f33d57ebc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type aiArticleListSponsoredArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
  readonly " $fragmentType": "aiArticleListSponsoredArticle";
};
export type aiArticleListSponsoredArticle$key = {
  readonly " $data"?: aiArticleListSponsoredArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"aiArticleListSponsoredArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "aiArticleListSponsoredArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeSecondaryContentItemContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "6f4d0e1c0f8876d81c62ae4dd3f01281";

export default node;
