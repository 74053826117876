import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { ContentItemHomeFocus } from "~/components/content/content-item-render/variants/home-focus";

export const StyledContentItemHomeFocus = styled(ContentItemHomeFocus)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${props => props.theme.breakpoints.up("tablet")} {
    gap: 16px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    gap: 12px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23.5px;
  text-transform: uppercase;
`;

type LiveDotProps = {
  $isLive: boolean;
};
export const LiveDot = styled.span<LiveDotProps>`
  display: inline-block;

  inline-size: 12px;
  block-size: 12px;
  margin-inline-end: 8px;

  border-radius: 50%;

  background-color: #ffca05;

  ${props =>
    props.$isLive &&
    css`
      animation: fadeInOut 1s infinite;
    `}
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;
