import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { LiveTag } from "~/components/content/content-live-tag/styles";
import { EntityLink } from "~/components/entity-link";
import { HomeFollowButton } from "~/components/home/follow-button";
import { HomeTopStoriesBlock } from "~/components/home/top-stories/top-stories-block";

export const Container = styled.div`
  display: grid;
  grid:
    "topic-name-follow" min-content
    "subsection" min-content
    "home-top-stories-block" min-content
    / 100%;

  margin-block-start: 32px;
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "topic-name-follow subsection" min-content
      "home-top-stories-block home-top-stories-block" min-content
      / max-content minmax(0, 1fr);

    margin-block-start: 48px;
  }
`;

export const TopicBadge = styled.div`
  grid-area: topic-name-follow;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  block-size: 24px;
  margin-block-end: 16px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const TopicName = styled(EntityLink)`
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const StyledTopicFollowButton = styled(HomeFollowButton)`
  margin-inline-start: 12px;
`;

export const Subsection = styled.div`
  grid-area: subsection;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
    margin-inline-start: 20px;
  }
`;

export const StyledHomeTopStoriesBlock = styled(HomeTopStoriesBlock)`
  grid-area: home-top-stories-block;

  ${LiveTag} {
    display: none;
  }
`;
