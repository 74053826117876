import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { LiveTag } from "~/components/content/content-live-tag/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const HeadlineContainer = styled.div`
  display: flex;
`;

export const Headline = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 140%;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 15px;
  }
`;

export const LiveTagContainer = styled.div`
  margin-inline-start: 12px;
  ${LiveTag} {
    inline-size: 62.69px;
    block-size: 32px;
  }
`;

export const ActionBarContainer = styled.div`
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
`;
