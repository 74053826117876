import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { forwardRef, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "~/components/common/base-link/context";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event } from "~/components/tracking/google-analytics/types";
import type { sectionTopQuery$key } from "~/queries/__generated__/sectionTopQuery.graphql";

import { Container } from "./styles";
import { SubsectionMenu } from "./subsection-menu";
import { SectionTopWidgetOne } from "./variant/one";
import { SectionTopWidgetTwo } from "./variant/two";

type Section = "china" | "hong-kong" | "lifestyle" | "people-culture" | "style" | "twia";

type Props = {
  className?: string;
  reference: sectionTopQuery$key;
  section: Section;
};

export const HomeSectionTop = forwardRef<HTMLDivElement, Props>(
  ({ className, reference, section }, elementReference) => {
    const data = useFragment(
      graphql`
        fragment sectionTopQuery on Query
        @argumentDefinitions(
          isAsiaEdition: { type: "Boolean!" }
          excludeSsrContentEntityIds: { type: "[String]" }
        ) {
          chinaSection: section(filter: { entityId: "4" }) {
            ...subsectionMenuSection
            name
          }
          hongKongSection: section(filter: { entityId: "2" }) @skip(if: $isAsiaEdition) {
            ...subsectionMenuSection
            name
          }
          lifestyleSection: section(filter: { entityId: "94" }) {
            ...subsectionMenuSection
            name
          }
          peopleCultureSection: section(filter: { entityId: "318202" }) {
            ...subsectionMenuSection
            name
          }
          styleSection: section(filter: { entityId: "72" }) {
            ...subsectionMenuSection
            name
          }
          twiaSection: section(filter: { entityId: "323045" }) @include(if: $isAsiaEdition) {
            ...subsectionMenuSection
            name
          }
          chinaSectionContent: queue(filter: { name: "section_top_4" }) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          hongKongSectionContent: queue(filter: { name: "section_top_2" })
            @skip(if: $isAsiaEdition) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          lifestyleSectionContent: queue(filter: { name: "section_top_94" }) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          peopleCultureSectionContent: queue(filter: { name: "section_top_318202" }) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          styleSectionContent: queue(filter: { name: "section_top_72" }) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          twiaSectionContent: queue(filter: { name: "section_top_323045" })
            @include(if: $isAsiaEdition) {
            ...oneSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
            ...twoSectionTopWidgetQueue @arguments(excludeEntityIds: $excludeSsrContentEntityIds)
          }
          chinaSubSections: queue(filter: { name: "section_categories_4" }) {
            items(first: 5) {
              ...subsectionMenuQueue
            }
          }
          hongKongSubSections: queue(filter: { name: "section_categories_2" })
            @skip(if: $isAsiaEdition) {
            items(first: 6) {
              ...subsectionMenuQueue
            }
          }
          lifestyleSubSections: queue(filter: { name: "section_categories_94" }) {
            items(first: 4) {
              ...subsectionMenuQueue
            }
          }
          peopleCultureSubSections: queue(filter: { name: "section_categories_318202" }) {
            items(first: 5) {
              ...subsectionMenuQueue
            }
          }
          styleSubSections: queue(filter: { name: "section_categories_72" }) {
            items(first: 7) {
              ...subsectionMenuQueue
            }
          }
          twiaSubSections: queue(filter: { name: "section_categories_323045" })
            @include(if: $isAsiaEdition) {
            items(first: 6) {
              ...subsectionMenuQueue
            }
          }
        }
      `,
      reference,
    );

    const { SectionBlockComponent, sectionContents, sectionEntity, subSections } = useMemo(() => {
      switch (section) {
        case "china":
          return {
            SectionBlockComponent: SectionTopWidgetOne,
            sectionContents: data.chinaSectionContent,
            sectionEntity: data.chinaSection,
            subSections: data.chinaSubSections?.items,
          };
        case "hong-kong":
          return {
            SectionBlockComponent: SectionTopWidgetOne,
            sectionContents: data.hongKongSectionContent,
            sectionEntity: data.hongKongSection,
            subSections: data.hongKongSubSections?.items,
          };
        case "lifestyle":
          return {
            SectionBlockComponent: SectionTopWidgetTwo,
            sectionContents: data.lifestyleSectionContent,
            sectionEntity: data.lifestyleSection,
            subSections: data.lifestyleSubSections?.items,
          };
        case "people-culture":
          return {
            SectionBlockComponent: SectionTopWidgetTwo,
            sectionContents: data.peopleCultureSectionContent,
            sectionEntity: data.peopleCultureSection,
            subSections: data.peopleCultureSubSections?.items,
          };
        case "style":
          return {
            SectionBlockComponent: SectionTopWidgetTwo,
            sectionContents: data.styleSectionContent,
            sectionEntity: data.styleSection,
            subSections: data.styleSubSections?.items,
          };
        case "twia":
          return {
            SectionBlockComponent: SectionTopWidgetOne,
            sectionContents: data.twiaSectionContent,
            sectionEntity: data.twiaSection,
            subSections: data.twiaSubSections?.items,
          };
      }
    }, [
      data.chinaSection,
      data.chinaSectionContent,
      data.chinaSubSections?.items,
      data.hongKongSection,
      data.hongKongSectionContent,
      data.hongKongSubSections?.items,
      data.lifestyleSection,
      data.lifestyleSectionContent,
      data.lifestyleSubSections?.items,
      data.peopleCultureSection,
      data.peopleCultureSectionContent,
      data.peopleCultureSubSections?.items,
      data.styleSection,
      data.styleSectionContent,
      data.styleSubSections?.items,
      data.twiaSection,
      data.twiaSectionContent,
      data.twiaSubSections?.items,
      section,
    ]);

    const impressionEvent = useCallback<() => Event>(
      () => ({
        action: `${sectionEntity?.name ?? ""}/Impression` as const,
        category: "HP Section Block",
      }),
      [sectionEntity?.name],
    );
    const { captureTrackImpressionEventTargetElement } = useTrackImpressionEventByIntersection<
      Event,
      null
    >({
      gaTrackingHandler: sendGATracking,
      getEvent: impressionEvent,
      options: { isSendGATracking: true, shouldSendOnce: true },
    });

    return (
      <BaseLinkContextProvider
        customQueryParameters={{
          module: `hp_section_${section}`,
        }}
      >
        <Container className={className} ref={elementReference}>
          <span ref={captureTrackImpressionEventTargetElement} />
          {subSections && sectionEntity && (
            <SubsectionMenu reference={subSections} sectionReference={sectionEntity} />
          )}
          {sectionContents && (
            <SectionBlockComponent
              reference={sectionContents}
              onClick={entityId => {
                sendGATracking({
                  action: `${sectionEntity?.name ?? ""}/Click` as const,
                  category: "HP Section Block",
                  label: entityId,
                });
              }}
            />
          )}
        </Container>
      </BaseLinkContextProvider>
    );
  },
);

HomeSectionTop.displayName = "HomeSectionTop";
