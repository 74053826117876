import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "~/components/common/base-link";
import { HomeFollowButton } from "~/components/home/follow-button";

import StyleIconSvg from "./style-icon.svg";

export const Container = styled.div`
  display: grid;
  grid:
    "section-name-follow" min-content
    "." 16px
    "subsection" min-content
    / 100%;

  margin-block-end: 20px;
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "section-name-follow subsection" min-content
      / max-content 1fr;

    margin-block-end: 24px;
  }
`;

export const SectionBadge = styled.div`
  grid-area: section-name-follow;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  block-size: 24px;
`;

export const SectionName = styled(BaseLink)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
  text-transform: uppercase;
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  margin-inline-start: 12px;
`;

export const StyledStyleIcon = styled(StyleIconSvg)`
  display: block;

  inline-size: 44px;
  block-size: 28px;
`;

export const Subsection = styled.div`
  grid-area: subsection;

  overflow-y: auto;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline-start: 20px;
  }
`;
